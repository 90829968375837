import Main from "@/components/Manage/Main";
import Manage from "@/components/Manage/UserManage/Manage";

const router = [{
    path: '/main',
    name: 'Main',
    component: Main,
    meta: {
        roleCode: 'main',
        requiresAuth: true,
    },
    children: [{
        path: '/manage',
        name: 'manage',
        component: Manage,
        meta: {
            roleCode: 'manage',
            requiresAuth: true
        }
    },]
}]


export default router
