import { render, staticRenderFns } from "./DictionaryTree.vue?vue&type=template&id=1d902d01&scoped=true&"
import script from "./DictionaryTree.vue?vue&type=script&lang=js&"
export * from "./DictionaryTree.vue?vue&type=script&lang=js&"
import style0 from "./DictionaryTree.vue?vue&type=style&index=0&id=1d902d01&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d902d01",
  null
  
)

export default component.exports