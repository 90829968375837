<template>
  <div>
    <el-input placeholder="输入关键字搜索" v-model="filterText"/>
    <el-tree
        style="width: 100%;border-radius: 5%"
        :data="dictionaryDataAll"
        :props="dictionaryDataProps"
        accordion
        :node-key="'id'"
        :render-after-expand="false"
        @node-click="handleLeftClick"
        @node-contextmenu="rightClick"
        :filter-node-method="filterNode"
        @node-expand="nodeExpand"
        @node-collapse="nodeCollapse"
        :default-expanded-keys = "expandedNodes"
        highlight-current
        ref="dictionaryDataTree">
    </el-tree>

    <!--弹窗-->
    <div class="box-menu" v-show="menuVisible" :style="{ left: menu_right + 'px', top: menu_top + 'px' }">
      <div class="add" @click="handleAdd()">
        <i class="el-icon-circle-plus-outline"></i>&nbsp;&nbsp;添加字典
      </div>
      <div class="edit" @click="handleEdit()">
        <i class="el-icon-edit"></i>&nbsp;&nbsp;编辑字典
      </div>
      <div class="delete" @click="deleteNode()">
        <i class="el-icon-remove-outline"></i>&nbsp;&nbsp;删除字典
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "DictionaryTree",
  props: {
    dictionaryDataAll: {
      type: Array,
      default: () => []
    },
    dictionaryDataProps: {
      type: Object,
      default: () => {
        return {
          id: 'id',
          label: 'label',
          children: 'children'
        }
      }
    },
  },
  data() {
    return {
      menu_right: 0,
      menu_top: 0,
      menuVisible: false,
      firstLevel: false,
      filterText: '',
      currentData: '',
      type: '',
      data: {
        formData: {},
        type: '',
        formVisible: true
      },
      expandedNodes: [] // 记录节点的展开状态
    }
  },
  watch: {
    filterText(val) {
      this.filterTree(val);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },

    filterTree(keywords) {
      this.$refs.dictionaryDataTree.filter(keywords);
    },

    // 鼠标右击事件
    rightClick(event, object, Node, element) {
      this.currentData = object
      this.currentNode = Node
      this.menuVisible = true
      document.addEventListener('click', this.foo)
      // 将菜单显示在鼠标点击旁边定位
      this.menu_right = event.clientX + 20;
      this.menu_top = event.clientY + 10;
    },
    // 鼠标左击事件
    handleLeftClick(object,data,node) {
      this.currentData = object
      this.detail();
      this.foo();
    },
    // 取消鼠标监听事件 菜单栏
    foo() {
      this.menuVisible = false
      // 及时关掉监听
      document.removeEventListener('click', this.foo)
    },

    //节点被展开时
    nodeExpand(object,data,node) {
      // 在节点点击事件中更新节点的展开状态
      this.expandedNodes.push(node.node.data.id);
    },
    //节点被收缩时
    nodeCollapse(object,data,node) {
      // 在节点点击事件中更新节点的展开状态
      this.expandedNodes = this.expandedNodes.filter(id => id !== node.node.data.id);
    },

    // 添加子字典
    handleAdd() {
      this.data = {
        formData: {},
        type: '',
        formVisible: true
      };
      this.data.formData.path = this.currentData.path;
      this.data.formData.level = this.currentData.level;
      this.data.formData.parentId = this.currentData.id;
      this.data.type = "add"
      this.data.formVisible = true;
      this.$emit('handleAdd', this.data);
    },
    // 编辑字典
    handleEdit() {
      this.data.formData= this.currentData;
      this.data.type = "edit"
      this.data.formVisible = true;
      this.$emit('handleEdit', this.data);
    },
    // 删除字典
    deleteNode() {
      this.$confirm("此操作将删除已选择数据, 是否继续?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.$emit('handleDelete',this.currentData.id);
          });
    },
    detail() {
      this.data.formData= this.currentData;
      this.data.type = "detail"
      this.data.formVisible = true;
      this.$emit('handleDetail', this.data);
    }

  },
};
</script>

<style scoped lang="less">

.el-tree>.is-leaf {
  color: transparent;
}

.tree-container /deep/ .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.tree-container /deep/ .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}


.tree-container /deep/ .el-icon-caret-right:before {
  //background: url("../../assets/node-collapse.png") no-repeat;
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  font-size: 12px;
  background-size: 10px;
}

/*
  //有子节点 且已展开*/
.tree-container /deep/ .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
  //background: url("../../assets/node-expand.png") no-repeat;
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  font-size: 12px;
  background-size: 10px;
}

/*  //没有子节点*/
.tree-container /deep/ .el-tree-node__expand-icon.is-leaf::before {
  background: transparent no-repeat 0 3px;
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  font-size: 12px;
  background-size: 10px;
}

/* 点击节点时的选中颜色 */
.tree-container /deep/.el-tree-node.is-current>.el-tree-node__content {
  color: #3D5ECC !important;
}

.tree-container /deep/ .el-tree-node__expand-icon {
  margin-left: 15px;
}

.tree-container /deep/ .el-tree-node__expand-icon.is-leaf {
  margin-left: 0px;
}

.tree-container /deep/ .el-tree-node {
  position: relative;
  padding-left: 16px;
}

.tree-container /deep/ .el-tree-node__children {
  padding-left: 16px;
}

.tree-container /deep/ .el-tree>.el-tree-node:before {
  border-left: none;
}

.tree-container /deep/ .el-tree>.el-tree-node:after {
  border-top: none;
}

.tree-container /deep/ .el-tree>.el-tree-node:before {
  border-left: none;
}

.tree-container /deep/ .el-tree>.el-tree-node:after {
  border-top: none;
}

.tree-container /deep/ .el-tree-node:before {
  content: "";
  left: 10px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.tree-container /deep/ .el-tree-node:after {
  content: "";
  left: 10px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.tree-container /deep/ .el-tree-node:before {
  border-left: 1px dashed #ccc;
  bottom: 0px;
  height: 100%;
  top: -19px;
  width: 1px;
}

.tree-container /deep/ .el-tree-node:after {
  border-top: 1px dashed #ccc;
  height: 25px;
  top: 20px;
  width: 20px;
}

.el-tree-node :last-child:before {
  height: 40px;
}

.tree-container {
  margin: 10px;
}

.tree-container /deep/ .el-tree .el-tree-node {
  position: relative;
}

.tree-container /deep/ .el-tree-node .el-tree-node__content {
  height: 34px;
  padding-left: 0px !important;
  border: none;
}

.tree-container /deep/ .el-tree-node .el-tree-node__content::before {
  border-left: 1px dashed #ccc;
  height: 100%;
  top: 0;
  width: 1px;
  margin-left: 1px;
  margin-top: 0px;
  z-index: 8;
}

.tree-container /deep/ .el-tree-node .el-tree-node__children .el-tree-node__content::before {
  border-left: 0px dashed #ccc;
  height: 100%;
  top: 0;
  width: 1px;
  margin-left: 1px;
  margin-top: 0px;
  z-index: 8;
}

.tree-container /deep/ .el-tree-node .el-tree-node__content::after {
  border-top: 1px dashed #ccc;
  height: 1px;
  top: 18px;
  width: 13px;
  margin-left: 1px;
  z-index: 8;
}

.tree-container /deep/ .el-tree-node .el-tree-node__children .el-tree-node__content::after {
  border-top: 0px dashed #ccc;
}

.tree-container .el-tree-node .el-tree-node__content::before,
.tree-container .el-tree-node .el-tree-node__content::after {
  content: "";
  position: absolute;
  right: auto;
}

.el-tree {
  width: 20%;
  margin-top: 10px;
}

.search {
  width: 20%;
}

.item {
  padding: 18px 0;
}

.search {
  cursor: pointer;
}

.box-menu {
  width: 150px;
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0px 0px 10px #ccc, 0px 0px 20px #ccc, 0px 0px 30px #ccc;
  padding: 10px;

  div {
    cursor: pointer;
    line-height: 30px;
  }

  div:active {
    color: blue;
  }
}
</style>
