<template>
  <div style="width: 100%;height: 100%">
    <h1 class="header">
      修改密码</h1>
    <el-form ref="pwdForm" :model="form" :rules="rules" label-width="90px" class="main">
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item prop="newPwd" label="新密码" style="margin-top: 30px">
            <el-input id="new" v-model="form.newPwd" show-password size="mini" style="width: 95%"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item prop="certainPassword" label="确认密码">
            <el-input id="certain" v-model="form.certainPassword" show-password style="width: 95%"
                      size="mini"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="button1">
        <el-button @click="cancel()" size="medium">取消</el-button>
        <el-button @click="save()" size="medium">确认</el-button>
      </div>
    </el-form>
  </div>

</template>
<script>
import changeService from "../../api/message"
import LoginService from "@/api/login";

export default {
  name: "Change",
  props: {
    userId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      form: {
        pwd: "",
        newPwd: "",
        certainPassword: "",
      },
      rules: {
        newPwd: [{
          required: true,
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (/^-/.test(value)) {
              callback(new Error("输入不能为负数"))
            } else if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!*.])[A-Za-z\d@#$%^&+=!*.]{6,20}$/.test(value))) {
              callback(new Error("密码需包含英文大小写和数字、特殊符号等且长度大于6小于20"))
            } else {
              callback()
            }
          }
        }],
        certainPassword: [{
          required: true,
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (value !== this.form.newPwd) {
              callback(new Error("确认密码与新密码不一致"))
            } else {
              callback()
            }
          }
        }],
      },
    }
  },
  methods: {
    save() {
      let isLogout = true;
      if (this.userId === '') {
        this.form.id = localStorage.getItem("userId");
      } else {
        this.form.id = this.userId;
        isLogout = false;
      }
      this.$refs['pwdForm'].validate((valid) => {
        if (valid) {
          changeService.change(this.form).then(res => {
            if (res.state) {
              this.$message.success(res.message);
              if (isLogout) {
                LoginService.logout().then(res => {
                  if (res.state) {
                    this.$router.push("/login");
                    this.$message.success(res.message);
                  }
                })
              } else {
                window.location.reload();
              }
            }
          })
        } else {
          return false;
        }
      })
    },
    cancel() {
      window.location.reload();
    },
  }
}
</script>

<style>
.header {
  background-color: white;
  color: black;
  border: #f6f6f8 solid;
  font-weight: bold;
  font-size: 20px;
  box-sizing: border-box;
  height: 70px;
  line-height: 65px;
  padding-left: 10px;
  margin: 0
}

.main {
  margin-left: 10px;
  background-color: white;
  width: 95%;
  margin-top: 30px;
  border-radius: 10px;
  border: thin solid #eaebec;
}

.button1 {
  display: flex;
  flex-direction: row;
  margin-left: 80%;
  margin-bottom: 1%;
}
</style>
