<template>
  <el-select
      class="tags-select"
      style="width: 100%"
      :disabled="disableOrNot"
      v-model="researchLabel"
      multiple
      placeholder="请选择"
      ref="selectRefs"
      :placeholder="placeholder"
      @change="handleSelectionChange"
      fixed>
    <el-input
        style="width: 75%"
        placeholder="输入关键字搜索"
        v-model="filterText"
        @click.stop.native
        class="sel-input"
        v-if="researchData.length !== 0">
    </el-input>
    <el-button @click="clearTreeSelect" type="primary" style="width: 20%">清除</el-button>
    <el-option v-model="researchLabel" class="sel-option" style="width: 100%">
      <el-tree
          style="width: 100%"
          :data="researchData"
          :props="researchDataProps"
          :default-checked-keys="existResearchIds"
          :default-expanded-keys="existResearchIds"
          :node-key="'id'"
          show-checkbox
          :render-after-expand="false"
          accordion
          @check-change="handleCheckChange"
          :filter-node-method="filterNode"
          ref="researchDataTree">
      </el-tree>
    </el-option>
  </el-select>
</template>

<script>

export default {
  name: 'ResearchTree',
  props: {
    disableOrNot: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: ''
    },
    researchData: {
      type: Array,
      default: () => []
    },
    researchDataProps: {
      type: Object,
      default: () => {
        return {
          id: 'id',
          label: 'label',
          children: 'children'
        }
      }
    },
    filterable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    existResearchIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      leafNodes: [],
      filterText: '',
      researchLabel: [],
      localResearchIds: [],
    }
  },
  watch: {
    filterText(val) {
      this.filterTree(val);
    },
    localResearchIds: {
      handler(newval, oldval) {
        if (newval) {
          this.$emit('update:researchIds', this.localResearchIds);
        }
      },
    },
    existResearchIds: {
      handler(newval, oldval) {
        if (newval && newval.length === 0){
          this.clearTreeSelect();
        }
      }
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    //节点选中状态发生变化时的回调
    handleCheckChange() {
      //获取所有选中的节点 start
      let res = this.$refs.researchDataTree.getCheckedNodes();
      let researchId = [];
      this.researchLabel = [];
      res.forEach((item) => {
        if (item.isLeaf) {
          researchId.push(item.id);
          this.researchLabel.push(item.label);
        }
      });
      this.localResearchIds = researchId;
    },

    filterTree(keywords) {
      this.$refs.researchDataTree.filter(keywords);
    },

    handleSelectionChange(value) {
      this.researchLabel = value;
    },

    //获取叶子节点
    getLeafNodes(tree) {
      if (!tree) {
        return [];
      }
      const leafNodes = [];
      const queue = [];

      // 初始根节点入队
      queue.push(...tree);

      while (queue.length) {
        const node = queue.shift(); // 出队处理当前节点

        // 判断当前节点是否为叶子节点，并且 isLeaf 为 true
        if (node.isLeaf) {
          leafNodes.push(node); // 添加当前叶子节点到结果列表
        }

        if (node.children) {
          // 将当前节点的子节点入队
          queue.push(...node.children);
        }
      }
      return leafNodes;
    },

    //清除
    clearTreeSelect() {
      this.$refs.researchDataTree.setCheckedKeys([]);
    }

  },

};
</script>

<style>
.sel-option {
  width: 300px;
  height: auto;
  max-height: 200px;
  overflow: auto;
  cursor: pointer;
  font-weight: normal !important;
  padding: 0 5px;
}

.sel-input {
  width: 300px;
  margin: 5px;
}
.sel-option .el-tag__close {
  display: none;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}

.tags-select .el-tag__close.el-icon-close {
  display: none;
}
</style>
