<template>
  <div class="wareHouseBody">
    <div class="flex-column height100">
      <div class="searchBox">
        <el-form label-width="72px">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="年份">
                <el-select v-model="searchParams.params.competitionYear" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    v-for="item in years"
                    :key="item.value"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="名称">
                <el-input v-model="searchParams.params.competitionName" placeholder="请输入比赛名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" type="flex" align="middle" style="margin-left: 15px">
            <el-col :span="15">
              <el-button type="primary" @click="handleAdd()" v-btn-permission="'user_add'">新增</el-button>
              <el-button type="primary" @click="exportFile()" :loading="exportLoading">导出</el-button>
              <el-button type="primary" @click="del()" v-btn-permission="'user_delete'" :disabled="disabledType">批量删除</el-button>
              <el-button type="primary" @click="load(1)">查询</el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="tableData" style="height: 80%">
        <el-table
            ref="filterTable"
            :data="competitions"
            :show-overflow-tooltip="true"
            @selection-change="handleSelectionChange"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="competitionYear" label="比赛年份" min-width="50"/>
          <el-table-column prop="competitionName" label="比赛名称" min-width="200"/>
          <el-table-column prop="projectYear" label="荣誉" min-width="50">
            <template slot-scope="scope">
              <el-button type="text" @click="viewAward(scope.row)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="viewItem(scope.row)">详情</el-button>
              <el-button @click="handleEdit(scope.row)" size="mini" v-btn-permission="'user_edit'">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="text-align: right;"
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="this.searchParams.pageBean.pageNum"
          :page-size="this.searchParams.pageBean.pageSize"
          :page-sizes="pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <AddOrModifyDialog v-if="dialogFormVisible" :dialogFormVisible.sync="dialogFormVisible" :competitionId="competitionId" :type="type" @refresh="load"/>
    <el-dialog title="荣誉" :visible.sync="dialogFormVisibleAward" v-if="dialogFormVisibleAward" width="80%" :close-on-click-modal="false">
      <award :competitionId="competitionId" :competitionYear="competitionYear" :isShowButton="isShowButton"></award>
    </el-dialog>
  </div>
</template>

<script>
import manageService from "@/api/manage";
import competitionService from "@/api/competition";
import award from "@/components/Manage/Awards/Awards";
import {processDataFlow} from "@/utils";
import AddOrModifyDialog from "./components/AddOrModify.vue";

export default {
name: 'competition',
components: {
  AddOrModifyDialog,
  award
},
data() {
  return {
    awardData: {},
    total: 0, //总条目数
    tableData: [],
    years: [],
    multipleSelection: [],
    addData: {},
    isShowButton: '',
    competitionId: '',
    type: '',
    competitionYear: '',
    competitionName: '',
    pageSizes: [10, 20, 30, 50, 100], //可选择的一页多少条
    dialogFormVisible_change: false,
    dialogFormVisibleAward: false,
    dialogFormVisible: false,
    exportLoading: false,
    disabledType: true,
    options: [],
    competitions: [],
    searchParams: {
      pageBean: {
        pageNum: 1,
        pageSize: 10
      },
      params: {
        competitionName: '',
        competitionYear: '',
      },
    },

  }
},
watch: {
  multipleSelection(val) {
    if (val.length > 0) {
      this.disabledType = false;
    } else {
      this.disabledType = true;
    }
  },
},
mounted() {
  this.load();
  this.searchAllYears();
},
methods: {
  refresh() {
    this.$router.go(0)
  },
  searchList() {
    this.load(1);
  },
  load(number) {
    if (number === 1) {
      this.searchParams.pageBean.pageNum = 1;
    }
    competitionService.searchList(this.searchParams)
        .then(res => {
          this.competitions = res.rows;
          this.total = res.total;
        });
  },
  searchAllYears(){
    competitionService.searchAllYears().then(res => {
      this.years = res.data;
    })
  },
  reset() {
    this.searchParams = {
      pageBean: {
        pageNum: 1,
        pageSize: 10
      },
      params: {
        competitionName: '',
        competitionYear: '',
      },
    }
    this.load()
  },
  handleSelectionChange(val) {
    this.multipleSelection = val
  },
  viewAward(row) {
    this.isShowButton = '2';
    this.competitionId = row.id;
    this.competitionYear = '' + row.competitionYear;
    this.$emit("tableLoading");
    this.dialogFormVisibleAward = true;
  },
  handleAdd() {
    this.type = "add";
    this.competitionId = undefined;
    this.dialogFormVisible = true;
  },
  handleEdit(row) {
    this.type = 'edit';
    this.competitionId = row.id;
    this.dialogFormVisible = true
  },
  viewItem(row) {
    this.type = 'detail';
    this.competitionId = row.id;
    this.dialogFormVisible = true
  },
  exportFile() {
    competitionService.export(this.searchParams)
        .then((res) => {
          processDataFlow(res, '比赛信息汇总表.xlsx')
        })
        .finally(() => {
          this.exportLoading = false
        })
  },
  del() {
    let ids = this.multipleSelection.map((item) => item.id).join(",");
    let self = this;
    self
        .$confirm("此操作将删除已选择数据, 是否继续?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          competitionService.delete(ids)
              .then((res) => {
                if (res.state) {
                  self.$message.success(res.message);
                } else {
                  self.$message.error(res.message);
                }
              }).finally(() => {
            self.load();
          });
        });
  },
  edit() {
    manageService.edit(this.edit_form).then(res => {
      if (res.state) {
        self.$message.success(res.message);
        this.dialogFormVisible_change = false;
      } else {
        self.$message.error(res.message);
      }
    }).finally(() => {
      self.load();
    });
  },
  //page改变时的回调函数，参数为当前页码
  currentChange(val) {
    this.searchParams.pageBean.pageNum = val;
    this.load();
  },
  //size改变时回调的函数，参数为当前的size
  sizeChange(val) {
    this.searchParams.pageBean.pageSize = val;
    this.load();
  },
  //筛选
  filterHandler(value, row, column) {
    const property = column['property'];
    return row[property] === value;
  },
}
}
</script>

<style scoped lang="scss">

//.wareHouseBody {
//margin: 0;
//padding-bottom: 0.0625rem;
//min-height: calc(100vh - 6.4rem);
//// ::v-deep .el-table .cell {
////   line-height: 32px;
////   white-space: normal !important;
////   &.el-tooltip {
////     white-space: nowrap !important;
////   }
//// }
//}

.flex-column {
display: flex;
flex-direction: column;
}

//.height100 {
//height: calc(100vh - 6.7rem);
//}

.searchBox {
margin-top: 10px;
padding: 20px;
}

.tableData {
//margin-top: 20px;
  border-radius: 20px;
  border: thin solid #eaebec;
  width: 98%;
  margin-left: 1%;
}
</style>
