<template>
  <div class="main">

    <div class="modal" v-show="visible">
      <img id="modalImg" src="" alt="" style="width: 455px; height: 700px;"  @click="close">
    </div>

    <div class="intro_top">
      <span>科研成果/</span>
      <em>RESEARCH RESULTS</em>
    </div>
    <div v-for="type in types" :key="type">
      <div class="intro_top">
        <span>{{ type }}/</span>
        <em>Research</em>
      </div>
      <div class="students-container">
        <div v-for="research in getResearchByType(type)" :key="research" class="card">
          <img :src="baseUrl + research.imageUrl" alt="" @click="upload(research.imageUrl)" />
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import ResearchService from "../../api/research"
import { BASE_URL } from '@/utils/constant';
export default {
  data(){
    return{
      visible: false,
      researchData: [],
      types: [],
      baseUrl: BASE_URL,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      ResearchService.queryResearchImages().then(res => {
        this.researchData = res.data;
        this.types = this.researchData.map(research => research.type);
        // 使用 Set 对象去除重复的项目名称
        this.types = [...new Set(this.types)];
      })
    },
    getResearchByType(type) {
      return this.researchData.filter(research => research.type === type);
    },
    showImage(imgId) {
      this.visible = true
      document.getElementById("modalImg").src = document.getElementById(imgId).src;
    },
    upload(pic) {
      window.open(this.baseUrl + pic, '_blank')
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.main{
  width:1200px;
  margin: 0 auto;
}
.card img {
  width: 100%;
  height: 100%;
}
.card {
  text-align: center;
  height: 400px;
  width: 260px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  display: inline-block;
  margin:10px 18px;
  vertical-align: top;
}
.card span {
  font-size: 12px;
  color: #bfbfbf;
  display: block;
  letter-spacing: 2px;
  padding: 30px 20px;
}
</style>

<style scoped >

.intro_top {
  margin-top: 30px;
  line-height: 38px;
  padding-right: 5px;
  padding-left: 5px;
  width: max-content;
  background-color: rgba(221, 237, 239, 0.8);
  border-radius: 5px;
}

.intro_top span {
  font-size: 28px;
  color: #1f2c5c;;
}

.intro_top em {
  font-size: 16px;
  color: #2d2d2d;
}
</style>

<style scoped>
.picture_box {
  /* background-color: blueviolet; */
  display: flex;
  flex-wrap: wrap;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(205, 215, 216, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
