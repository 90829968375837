<template>
  <div class="wareHouseBody">

    <dictionary-tree
        style="width: 50%"
        :dictionaryDataAll="dictionaryDataAll"
        :dictionaryDataProps="{id: 'id',label: 'name',children: 'children'}"
        @handleAdd="handleAdd"
        @handleEdit="handleEdit"
        @handleDetail="handleDetail"
        @handleDelete="handleDelete"
        placeholder="请选择学生姓名"
        class="position-select"
        size="mini"
        filterable>
    </dictionary-tree>

    <el-form :model="data" v-if="formVisible" class="form-dictionary" ref="dictionaryData" :rules="rules">
      <h1 style="margin-bottom: 20px;color: black">{{diaTitle}}</h1>
      <el-form-item label="字典编码" prop="code">
          <el-input :disabled="disableOrNot" v-model="data.code" placeholder="请输入字典编码" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="字典名称" prop="name">
          <el-input :disabled="disableOrNot" v-model="data.name" placeholder="请输入字典名称" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="排序号码" prop="index">
          <el-input :disabled="disableOrNot" v-model="data.index" placeholder="请输入排序号" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="enableFlag">
          <el-select :disabled="disableOrNot" v-model="data.enableFlag" style="width: 80%">
            <el-option
                v-for="item in YesOrNot"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <div style="text-align: right">
          <el-button @click="handleClose()" v-if="!disableOrNot">取消</el-button>
          <el-button type="primary" @click="save()" v-if="!disableOrNot">确定</el-button>
        </div>
    </el-form>
  </div>
</template>

<script>

import dictionaryService from "@/api/dictionary"
import DictionaryTree from "@/components/tree/DictionaryTree.vue";

export default {
  name: 'dictionary',
  components: {
    DictionaryTree,
  },
  data() {
    return {
      dictionaryDataAll: [],
      data: {},
      formVisible: false,
      type: '',
      YesOrNot: [
        {
          name: '是',
          value: "1"
        },
        {
          name: '否',
          value: "0"
        },
      ],
      rules: {
        code: [{
          required: true,
          message: '字典编码不能为空',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '字典名称不能为空',
          trigger: 'blur'
        }],
        index: [{
          required: true,
          message: '排序号不能为空',
          trigger: 'blur'
        }],
        enableFlag: [{
          required: true,
          message: '是否启用不能为空',
          trigger: 'blur'
        }],
      },
      disableOrNot: false,
      diaTitle: ''
    }
  },
  watch: {
    type: {
      handler(newval, oldval) {
        if (newval === 'add') {
          this.diaTitle = '新增'
          this.disableOrNot = false
        }
        if (newval === 'edit') {
          this.diaTitle = '编辑'
          this.disableOrNot = false
        }
        if (newval === 'detail') {
          this.diaTitle = '详情'
          this.disableOrNot = true
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(){
      return new Promise((resolve, reject) => {
        dictionaryService.queryRootDic().then((res) => {
          this.dictionaryDataAll = [];
          this.dictionaryDataAll.push(res.data);
          resolve();
        })
      })
    },
    handleAdd(addData) {
      this.formVisible = addData.formVisible;
      this.type = addData.type;
      this.data = addData.formData;
    },
    handleEdit(editData) {
      this.formVisible = editData.formVisible;
      this.type = editData.type;
      this.data = editData.formData;
    },
    handleDetail(detailData) {
      this.formVisible = detailData.formVisible;
      this.data = detailData.formData;
      this.type = detailData.type;
    },
    handleDelete(id){
      dictionaryService.delete(id)
          .then((res) => {
            if (res.state) {
              self.$message.success(res.message);
            } else {
              self.$message.error(res.message);
            }
          }).finally(() => {
        this.loadData();
      });
    },
    save() {
      this.$refs['dictionaryData'].validate((valid) => {
        if (valid) {
          if (this.type === 'add') {
            dictionaryService.insertOrUpdate(this.data).then(res => {
              if (res.state) {
                this.$message.success('添加成功')
                this.handleClose();
                this.loadData();
              }
            })
          }
          if (this.type === 'edit') {
            dictionaryService.insertOrUpdate(this.data).then((res) => {
              if (res.state) {
                this.$message.success('修改成功')
                this.handleClose();
                this.loadData();
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.data = {};
      this.formVisible = false;
    },
  }
}
</script>

<style scoped lang="scss">
.wareHouseBody {
  display: flex;
}
.position-select {
  margin-left: 1%;
  margin-top: 1%;
}
.form-dictionary {
  padding: 2%;
  background-color: white;
  width: 47%;
  margin-left: 1%;
  margin-top: 1%;
  border-radius: 5%;
}
</style>
