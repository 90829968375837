<template>
  <el-select
      style="width: 100%"
      :disabled="disableOrNot"
      v-model="projectName"
      placeholder="请选择项目名称"
      ref="selectRefs"
      :placeholder="placeholder"
      fixed>
    <el-input
        style="width: 75%"
        placeholder="输入关键字搜索"
        v-model="filterText"
        @click.stop.native
        class="sel-input"
        v-if="projectData.length !== 0">
    </el-input>
    <el-button @click="clearTreeSelect" type="primary" style="width: 20%">清除</el-button>
    <el-option :value="projectId" class="sel-option" style="width: 100%">
      <el-tree
          :data="projectData"
          :props="projectDataProps"
          :node-key="'id'"
          :default-expanded-keys="existProjectId"
          accordion
          @node-click="handleNodeClick"
          :filter-node-method="filterNode"
          ref="projectDataTree">
      </el-tree>
    </el-option>
  </el-select>
</template>

<script>

export default {
  name: 'ProjectTree',
  props: {
    disableOrNot: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: ''
    },
    projectId: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    projectData: {
      type: Array,
      default: () => []
    },
    existProjectId: {
      type: Array,
      default: () => []
    },
    projectDataProps: {
      type: Object,
      default: () => {
        return {
          value: 'id',
          label: 'label',
          children: 'children'
        }
      }
    },
  },
  data() {
    return {
      projectName: '',
      filterText: '',
    }
  },
  watch: {
    filterText(val) {
      this.$refs.projectDataTree.filter(val);
    },
    projectId: {
      handler(newval, oldval) {
        if (newval || newval === "") {
          this.$emit('update:projectId', this.projectId);
        }
      },
    },
    existProjectId: {
      handler(newval, oldval) {
        if (newval && newval.length > 0){
          this.leafNodes = this.getLeafNodes(this.projectData);
          let node = this.leafNodes.find(item => newval.includes(item.id));
          if (node && node.id && node.label) {
            this.projectName = node.label;
          }
        }else if (newval.length === 0){
          this.projectName = ''
        }
      }
    }
  },

  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleNodeClick(node) {
      if (node.isLeaf) {
        this.projectId = node.id // 添加选中的项
        this.projectName = node.label // 添加选中的项
      } else {
        return false;
      }
      this.$refs.selectRefs.blur() // 收起下拉框
    },

    //获取叶子节点
    getLeafNodes(tree) {
      if (!tree) {
        return [];
      }
      const leafNodes = [];
      const queue = [];

      // 初始根节点入队
      queue.push(...tree);

      while (queue.length) {
        const node = queue.shift(); // 出队处理当前节点

        // 判断当前节点是否为叶子节点，并且 isLeaf 为 true
        if (node.isLeaf) {
          leafNodes.push(node); // 添加当前叶子节点到结果列表
        }

        if (node.children) {
          // 将当前节点的子节点入队
          queue.push(...node.children);
        }
      }
      return leafNodes;
    },

    //清除
    clearTreeSelect() {
      this.projectName = '';
      this.projectId = '';
    },

  },
};
</script>

<style scoped>
.sel-option {
  width: 300px;
  height: auto;
  max-height: 200px;
  overflow: auto;
  cursor: pointer;
  font-weight: normal !important;
  padding: 0 5px;
}

.sel-input {
  width: 300px;
  margin: 5px;
}
</style>
