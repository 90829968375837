<template>
  <div class="main">

    <div class="modal" v-show="visible">
      <img id="modalImg" src="" alt="" style="width: 900px; "  @click="close">
    </div>

     <div class="intro_top">
       <span>日常科研/</span>
       <em>ROUTINE</em>
     </div>
     <div class="picture">
         <div v-for="activity in activityData" :key="activity">
           <div class="card">
             <img :src="baseUrl + activity.imageUrl" alt="" @click="upload(activity.imageUrl)"/>
           </div>

     </div>
    
     </div>
     
   
     
   </div>
 </template>
 
 <script>
 import ActivityService from "@/api/activity";
 import { BASE_URL } from '@/utils/constant';

 export default {
  data(){
    return{
      visible: false,
      activityData: [],
      baseUrl: BASE_URL,
    }
  },

   mounted() {
     this.load();
   },
   methods: {
     load() {
       ActivityService.queryProjectImages().then(res => {
         this.activityData = res.data.filter(research => research.type === "日常科研");
       })
     },
     upload(pic) {
       window.open(this.baseUrl + pic, '_blank')
     },

    showImage(imgId) {

      this.visible = true

      document.getElementById("modalImg").src = document.getElementById(imgId).src;



    },

    close() {
      this.visible = false
    }
  }
 }
 </script>
 
 <style lang="less" scoped>
 .main{
   width:1200px;
   margin: 0 auto;
 }

 .card img {
  width: 320px;
  height: 240px;
  border-radius: 4px;
 }
 .card {
   height: max-content;
   width: max-content;
   background-color: white;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   border-radius: 4px;
   display: inline-block;
   margin:10px 18px;
   vertical-align: top;
   margin-left: 40px;
 }

 .card .text {
  // background-color: rgb(196, 117, 21);
  width: 220px;
  padding-left: 5px;
 }

 .text {
  text-indent: 30px;
 }
 .card span {
   font-size: 12px;
   color: #bfbfbf;
   display: block;
   letter-spacing: 2px;
   padding: 30px 20px;
 }
 </style>
 
 <style >
 
 .intro_top {
   margin-top: 30px;
   line-height: 38px;
 }
 
 .intro_top span {
   font-size: 28px;
   color: #1f2c5c;;
 }
 
 .intro_top em {
   font-size: 16px;
   color: #2d2d2d;
 }
 </style>

 <style scoped>
 .picture{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    /* background-color: aqua; */
    padding-left: 20px;
 }

 .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(205, 215, 216, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>