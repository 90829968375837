<template>
  <div>
    <el-dialog width="40%" title="新增" :visible.sync="dialogVisb" @close="handleClose" :close-on-click-modal="false">
      <el-form :model="data" label-width="120px" ref="manageData" :rules="rules">
        <el-row :gutter="24">
          <el-col :span="20">
            <el-form-item label="姓名" prop="userName">
              <el-input v-model="data.userName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="20">
            <el-form-item label="密码" prop="pwd">
              <el-input v-model="data.pwd"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="20">
            <el-form-item label="身份" prop="roleId">
              <el-select v-model="data.roleId" style="width: 100%">
                <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.description"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="20">
            <el-form-item label="是否启用" prop="enableFlag">
              <el-select v-model="data.enableFlag" style="width: 100%">
                <el-option
                    v-for="item in YesOrNot"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="20">
            <el-form-item label="是否展示" prop="isShow">
              <el-select v-model="data.isShow" style="width: 100%">
                <el-option
                    v-for="item in YesOrNot"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="20">
            <el-form-item label="是否团队负责人" prop="isManage">
              <el-select v-model="data.isManage" style="width: 100%" @change="handleManageChange">
                <el-option
                    v-for="item in YesOrNot"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="管理年份" prop="ManageYear" v-if="isManager">
              <div class="block">
                <el-date-picker
                    style="width: 80%"
                    :disabled="disableOrNot"
                    v-model="data.manageYear"
                    type="year"
                    value-format="yyyy"
                    placeholder="请选择管理年份">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: right">
        <el-button type="primary" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="save()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import manageService from "@/api/manage"

export default {
  name: "AddUser",
  props: {
    data: {
      default: ''
    },
    dialogFormVisible: Boolean,
  },
  data() {
    return {
      roleList: [],
      academies: ['电子信息工程学院', '智能科学与控制工程学院', '网络工程与通信学院'],
      YesOrNot: [
        {
          name: '是',
          value: "1"
        },
        {
          name: '否',
          value: "0"
        },
      ],
      isManager: false,
      headersObj: {
        Satoken:window.localStorage.getItem('satoken'),
        Authorization:window.localStorage.getItem('satoken')
      },
      rules: {
        userName: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }],
        roleId: [{
          required: true,
          message: '身份不能为空',
          trigger: 'change'
        }],
        enableFlag: [{
          required: true,
          message: '是否启用不能为空',
          trigger: 'change'
        }],
      }
    }
  },
  computed: {
    dialogVisb: {
      get() {
        return this.dialogFormVisible
      },
      set(v) {
        this.$emit('update:dialogFormVisible', v)
      },
    },
  },
  mounted() {
    this.searchAllRoles();
  },
  methods: {
    handleManageChange() {
      this.isManager = this.data.isManage === "1";
    },
    searchAllRoles() {
      manageService.searchAllRoles().then(res => {
        this.roleList = res.data;
        const roleId = localStorage.getItem("roleId");
        if (roleId === "1002") {
          this.roleList = this.roleList.filter(item => item.name !== "admin");
        }
      })
    },
    handleClose() {
      this.data = {}
      this.dialogVisb = false
    },
    save() {
      this.$refs['manageData'].validate((valid) => {
        if (valid) {
          manageService.addUser(this.data).then(res => {
            if (res.state) {
              this.$message.success(res.message);
              this.handleClose()
              this.$emit('refresh')
            } else {
              this.$message.warning(res.message);
              return false;
            }
          })
        } else {
          return false;
        }
      })
    },
    // 文件上传
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleFileSuccess(response, file, fileList) {
      this.data.imageUrl = response.data;
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isLt2M;
    },
  }
}
</script>
<style>

</style>
