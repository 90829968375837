<template>
  <div class="main">

    <div class="modal" v-show="visible">
      <img id="modalImg" src="" alt="" style="width: 1024px; height: 600px;"  @click="close">
    </div>

    <div class="intro_top">
      <span>项目展示/</span>
      <em>PROJECT SHOWCASE</em>
    </div>
    <div v-for="projectName in projectNames" :key="projectName">
      <div class="intro_top">
        <span>{{ projectName }}</span>
      </div>
      <div class="students-container">
        <div v-for="image in getImagesByProject(projectName)" class="card">
          <img :src="baseUrl + image.imageUrl" alt="" @click="upload(image.imageUrl)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectService from "../../api/project"
import { BASE_URL } from '@/utils/constant';

export default {
  data(){
    return{
      visible: false,
      baseUrl: BASE_URL,
      show: false,
      projectData: [],
      projectNames: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      ProjectService.queryProjectImages().then(res => {
        this.projectData = res.data;
        this.projectNames = this.projectData.map(project => project.projectName);
        // 使用 Set 对象去除重复的项目名称
        this.projectNames = [...new Set(this.projectNames)];
      })
    },
    getImagesByProject(name) {
      return this.projectData.filter(project => project.projectName === name);
    },
    upload(pic) {
      window.open(this.baseUrl + pic, '_blank')
    },
    showImage(imgId) {
      this.visible = true
      document.getElementById("modalImg").src = document.getElementById(imgId).src;
    },

    close() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.main{
  width:1200px;
  margin: 0 auto;
}
.card img {
  width: 100%;
}
.card {
  flex: none;
  height: max-content;
  width: 363px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  display: inline-block;
  margin:10px 18px;
  vertical-align: top;
}

.card span {
  font-size: 12px;
  color: #bfbfbf;
  display: block;
  letter-spacing: 2px;
  padding: 30px 20px;
}
</style>

<style scoped>

.intro_top {
  margin-top: 30px;
  line-height: 38px;
  padding-right: 5px;
  padding-left: 5px;
  width: max-content;
  background-color: rgba(221, 237, 239, 0.8);
  border-radius: 5px;
}

.intro_top span {
  font-size: 28px;
  color: #1f2c5c;;
}

.intro_top em {
  font-size: 16px;
  color: #2d2d2d;
}
</style>

<style scoped>
  .show {
    background-color: rgba(255, 255, 255,0);
    display: flex;
    flex-wrap: wrap;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(205, 215, 216, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
/* 底部 */
  .bottomTop{
    height: 100px;
  }
  .footer{
    padding: 20px 10% 10px;
    background-color:black;
    color: #fff;
    height: 130px;
  }
  .boxf {
    height:80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:small;
    line-height: 1.875;
  }
  .footerr{
    padding-top: 1px;
    width: 100%;
    height: 40px;
    background-color:  #2c2c2c;
    font-size:10px;
    color: #fff;
    line-height: 1.5;
    text-transform: uppercase;
    text-align: center;
  }

  .logof{
    width: 260px;
    height: 110px;
    padding-left: 40px;
  }
</style>