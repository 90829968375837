<template>
  <div class="main">
    <div class="intro_top">
      <span>优秀成员/</span>
      <em>MEMBERS</em>
    </div>
    <div v-for="year in entryYears" :key="year">
      <div class="intro_top">
        <span>{{ year }}级/</span>
        <em>MEMBERS</em>
      </div>
      <div class="students-container">
      <div v-for="student in getStudentsByYear(year)" :key="student.userName" class="card" @click="toInfo(student.userName)">
        <img :src="baseUrl + student.imageUrl" alt="" />
        <div class="text" style="margin: 10px;">
          <p style="font-size:20px;color: brown ;">{{ student.userName }}</p>
          <p style="font-size: 13px;"></p>
        </div>
      </div>
      </div>

    </div>
    <div>
<!--    <div class="intro_top">-->
<!--      <span>18级/</span>-->
<!--      <em>MEMBERS</em>-->
<!--    </div>-->
<!--    <div class="picture">-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/沈亮.jpg" alt="" @click="shenliangInfo"/>-->
<!--        <div  class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">沈亮</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="intro_top">-->
<!--      <span>19级/</span>-->
<!--      <em>MEMBERS</em>-->
<!--    </div>-->
<!--    <div class="picture">-->
<!--      <div class="card_1">-->
<!--        <img src="@/assets/img/member/苏杰.jpg" alt="" @click="sujieInfo" />-->
<!--        <div  class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">苏杰</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card_1">-->
<!--        <img src="@/assets/img/member/潘星颖.jpg" alt="" @click="PanXingYingInfo"/>-->
<!--        <div  class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">潘星颖</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card_1">-->
<!--        <img src="@/assets/img/member/贾方.jpg" alt="" @click="jiafangInfo"/>-->
<!--        <div  class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">贾方</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card_1">-->
<!--        <img src="@/assets/img/member/梁楷博.jpg" alt="" @click="liangkaiboInfo"/>-->
<!--        <div  class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">梁楷博</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card_1">-->
<!--        <img src="@/assets/img/member/郑坤.jpg" alt="" @click="zhengkunInfo"/>-->
<!--        <div  class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">郑坤</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="intro_top">-->
<!--      <span>20级/</span>-->
<!--      <em>MEMBERS</em>-->
<!--    </div>-->
<!--    <div class="picture">-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/许佳彤.jpg" alt="" @click="xvjiatongInfo"/>-->
<!--        <div  class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">许佳彤</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/许彬.jpg" alt="" @click="xvbinInfo"/>-->
<!--        <div  class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">许彬</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/牛帅.jpg" alt="" @click="niushuaiInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">牛帅</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/何淼.jpg" alt="" @click="hemiaoInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">何淼</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/徐智凡.jpg" alt="" @click="xvzhifanInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">徐智凡</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/庞启明.jpg" alt="" @click="pangqimingInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">庞启明</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/陆昱帆.jpg" alt="" @click="luyufanInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">陆昱帆</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/周圩颖.jpg" alt="" @click="zhouweiyingInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">周圩颖</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="intro_top">-->
<!--      <span>21级/</span>-->
<!--      <em>MEMBERS</em>-->
<!--    </div>-->
<!--    <div class="picture">-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/王蓉.jpg" alt="" @click="wangrongInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">王蓉</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/张传帆.jpg" alt="" @click="zhangchuanfanInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">张传帆</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/朱子轩.jpg" alt="" @click="zhuzixuanInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">朱子轩</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/方咏绮.jpg" alt="" @click="fangyongqiInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">方咏绮</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/韩帅.jpg" alt="" @click="hanshuaiInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">韩帅</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/陈文豪.jpg" alt="" @click="chenwenhaoInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">陈文豪</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/吴周承.jpg" alt="" @click="wuzhouchengInof"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">吴周承</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/丁志强.jpg" alt="" @click="dingzhiqiangInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">丁志强</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="intro_top">-->
<!--      <span>22级/</span>-->
<!--      <em>MEMBERS</em>-->
<!--    </div>-->
<!--    <div class="picture">-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/范海炜.jpg" alt="" @click="fanhaiweiInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">范海炜</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/邹天铸.jpg" alt="" @click="zoutianzhuInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">邹天铸</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/杜玉杰.jpg" alt="" @click="duyvjieInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">杜玉杰</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/汤轩哲.jpg" alt="" @click="tangxuanzheInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">汤轩哲</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/赵雨欣.jpg" alt="" @click="zhaoyvxinInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">赵雨欣</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/于欣池.jpg" alt="" @click="yvxinchiInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">于欣池</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/李蕊.jpg" alt="" @click="liruiInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">李蕊</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card">-->
<!--        <img src="@/assets/img/member/王青治.jpg" alt="" @click="wangqingzhiInfo"/>-->
<!--        <div class="text" style="margin: 10px;">-->
<!--          <p style="font-size:20px;color: brown ;">王青治</p>-->
<!--          <p style="font-size: 13px;"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    </div>
  </div>
</template>

<script>
import MessageService from "../../api/message"
import { BASE_URL } from '@/utils/constant';

export default {
  data() {
    return {
      baseUrl: BASE_URL,
      entryYears: [],
      students: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      MessageService.queryIsShowUser().then(res => {
        localStorage.setItem('userInfo',JSON.stringify(res.data));
        this.students = res.data.filter(item => item.roleId === "1003");
        const years = this.students.map(student => student.entryYear);
        this.entryYears = [...new Set(years)].sort((a, b) => a - b);
      })
    },
    getStudentsByYear(year) {
      return this.students.filter(student => student.entryYear === year);
    },
    toInfo(userName) {
      this.$router.push({ name: 'information', params: { userName: userName } });
    },
  }
}
</script>


<style lang="less" scoped>
.main{
  width:1200px;
  margin: 0 auto;
}
.students-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card img {
  width: 260px;
  height: 320px;
  border-radius: 4px;
}
.card_1 img {
  width: 310px;
  height: 390px;
  border-radius: 4px;
}

.picture{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  // background-color: aqua;
}

.card,.card_1 {
  text-align: center;
  height: max-content;
  width: max-content;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  display: inline-block;
  margin:10px 18px;
  vertical-align: top;
}

.card:hover {
  transform: scale(1.1);
  transition: 0.3s;
  cursor: pointer;
}
.card_1:hover {
  transform: scale(1.1);
  transition: 0.3s;
  cursor: pointer;
}

.card .text ,.card_1 .text{
  background-color: rgb(255, 255, 255);

}
.card span {
  font-size: 12px;
  color: #bfbfbf;
  display: block;
  letter-spacing: 2px;
  padding: 30px 20px;
}
</style>

<style scoped>

.intro_top {
  margin-top: 30px;
  line-height: 38px;
  padding-right: 5px;
  padding-left: 5px;
  width: max-content;
  background-color: rgba(221, 237, 239, 0.8);
  border-radius: 5px;
}

.intro_top span {
  font-size: 28px;
  color: #1f2c5c;;
}

.intro_top em {
  font-size: 16px;
  color: #2d2d2d;
}
</style>
