import Vue from 'vue'

Vue.directive('btnPermission',{
    inserted(el,binding) {
        const permission = localStorage.getItem("permission");
        if(permission.indexOf(binding.value) === -1) {
            el.style.display = 'none';
            el.classList.add('is-disabled');
        }
    },
})

// 权限禁用
Vue.directive('forbiddenPermission', {
    inserted(el, binding, vnode, oldVnode) {
        const { value } = binding
        const userRoles = store.getters && store.getters.userRoles

        if (value) {
            if (!userRoles || !userRoles.buttonAuth || !userRoles.buttonAuth.includes(value)) {
                // 禁用按钮或设置输入框为只读
                if (el.classList.contains('el-button')) {  // 禁用按钮
                    el.parentNode && el.parentNode.removeChild(el);
                } else if (el.classList.contains('el-input')) { // 禁用输入框
                    el.classList.add('is-disabled');
                    el.querySelector('.el-input__inner').setAttribute('disabled', 'disabled');
                } else if (el.classList.contains('el-checkbox')) {  // 禁用复选框
                    setTimeout(() => {
                        el.classList.add('is-disabled');
                        el.querySelector('.el-checkbox__input').classList.add('is-disabled');
                        el.querySelector('.el-checkbox__original').setAttribute('disabled', 'disabled');
                    },400)
                } else if (el.classList.contains('el-select')) {  // 禁用下拉框
                    el.querySelector('.el-input--suffix').classList.add('is-disabled');
                    el.querySelector('.el-input__inner').setAttribute('disabled', 'disabled');
                    // 禁用切换下拉展示
                    const dropdown = el.__vue__;
                    dropdown.toggleMenu = () => {}
                }
            }
        } else {
            throw new Error('Need button key! Like v-permission="\'test\'"');
        }
    }
})
