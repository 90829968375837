import request from '@/utils/request.js';

export default {

    searchList(data) {
        return request({
            url:"/competition/page",
            method:"post",
            data
        })
    },
    export(data) {
        return request({
            url:"/competition/export",
            method:"post",
            responseType:'blob',
            data
        })
    },
    delete(ids) {
        return request({
            url:`/competition/remove?ids=${ids}`,
            method:"post"
        })
    },
    searchUsers() {
        return request({
            url:"/competition/searchAllYears",
            method:"get"
        })
    },
    insertOrUpdate(data) {
        return request({
            url:"/competition/insertOrUpdate",
            method:"post",
            data
        })
    },
    searchAllYears() {
        return request({
            url:"/competition/searchAllYears",
            method:"get"
        })
    },
    getById(id){
        return request({
            url:`/competition/getById/${id}`,
            method:"get"
        })
    },
    searchByYear(year){
        return request({
            url:`/competition/searchByYear?year=${year}`,
            method:"post"
        })
    }
}