import request from "@/utils/request";

export default {
    searchAllType(){
        return request({
            url: "/research/queryAllType",
            method: "get"
        })
    },
    searchAllYear(){
        return request({
            url: "/research/queryAllYear",
            method: "get"
        })
    },
    searchList(data) {
        return request({
            url: "/research/page",
            method: "post",
            data
        })
    },
    delete(ids) {
        return request({
            url:`/research/remove?ids=${ids}`,
            method:"post"
        })
    },
    insertOrUpdate(data) {
        return request({
            url:"/research/insertOrUpdate",
            method:"post",
            data
        })
    },
    queryById(id) {
        return request({
            url:`/research/queryById?id=${id}`,
            method:"post",
        })
    },
    uploadImage(data) {
        return request({
            type: "file",
            url: "/research/uploadImage",
            method: "post",
            data
        })
    },
    queryResearchImages() {
        return request({
            url: "/research/queryResearchImages",
            method: "get"
        })
    },
    uploadFile(data) {
        return request({
            type: "file",
            url: "/research/uploadFile",
            method: "post",
            data
        })
    },
    queryAllResearch() {
        return request({
            url: "/research/queryAllResearch",
            method: "get"
        })
    },
    export(data) {
        return request({
            url:"/research/export",
            method:"post",
            responseType:'blob',
            data
        })
    },
}