import request from '@/utils/request.js';

export default {
    searchList(data) {
        return request({
            url:"/award/page",
            method:"post",
            data
        })
    },
    export(data) {
        return request({
            url:"/award/export",
            method:"post",
            responseType:'blob',
            data
        })
    },
    insertOrUpdate(data) {
        return request({
            url:"/award/insertOrUpdate",
            method:"post",
            data
        })
    },
    delete(ids) {
        return request({
            url:`/award/remove?ids=${ids}`,
            method:"post"
        })
    },
    searchAllYears() {
        return request({
            url:"/award/searchAllYears",
            method:"get"
        })
    },
    getById(id){
        return request({
            url:`/award/getById/${id}`,
            method:"get"
        })
    },
    download(filePath){
        return request({
            url:`/award/download?filePath=${filePath}`,
            method:"get",
            responseType:'blob'
        })
    },
    uploadFile(data) {
        return request({
            type: "file",
            url: "/award/upload",
            method: "post",
            data
        })
    },
    searchBaseAwards() {
        return request({
            url:"/award/searchBaseAwards",
            method:"get"
        })
    }
}
