import request from '@/utils/request.js';

export default {

    insertOrUpdate(data) {
        return request({
            url:"/dictionary/insertOrUpdate",
            method:"post",
            data
        })
    },
    queryRootDic() {
        return request({
            url:"/dictionary/queryRootDic",
            method:"get"
        })
    },
    getById(id){
        return request({
            url:`/dictionary/getById/${id}`,
            method:"get"
        })
    },
    getDicByCode(code) {
        return request({
            url:`/dictionary/getDicByCode?code=${code}`,
            method:"get"
        })
    },
    getAllDicByCode(code) {
        return request({
            url:`/dictionary/getAllDicByCode?code=${code}`,
            method:"get"
        })
    },
    delete(id) {
        return request({
            url:`/dictionary/remove?id=${id}`,
            method:"post"
        })
    },
}
