<template>
  <div>
    <el-dialog width="40%" :title="diaTitle" :visible.sync="dialogVisb" @close="handleClose" :close-on-click-modal="false">
      <el-form :model="data" label-width="80px" ref="activityData" :rules="rules">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="活动类型" prop="type">
              <el-select :disabled="disableOrNot" v-model="data.type" style="width: 100%">
                <el-option
                    v-for="item in activities"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="活动图片" v-if="imageIsNone">
              <el-upload v-if="type === 'edit' || type === 'add'"
                         :on-preview="upload"
                         :on-remove="handleRemove"
                         :before-upload="beforeUpload"
                         :file-list="imageList"
                         action
                         multiple>
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <el-link
                  v-if="type === 'detail'"
                  class="mr30"
                  style="display: block"
                  :href="baseUrl + data.imageUrl"
                  type="primary"
                  target="_blank"
              >{{ data.imageUrl.match(/[^\/]+$/)[0] }}
              </el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改人" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updatedBy"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改时间" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updationDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: right">
        <el-button @click="handleClose()">取消</el-button>
        <el-button v-if="!disableOrNot" type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { BASE_URL } from '@/utils/constant';
import {nanoid} from "nanoid";
import dictionary from "@/api/dictionary";

export default {
  name: 'AddOrModifyDialog',
  props: {
    type: String,
    activityId: String,
    dialogFormVisible: Boolean,
  },
  data() {
    return {
      disableOrNot: false,
      diaTitle: '',
      data: {},
      rules: {
        type: [{
          required: true,
          message: '活动类型不能为空',
          trigger: 'change'
        }]
      },
      baseUrl : BASE_URL,
      imageIsNone: true,
      imageList: [],
      activities: []
    }
  },
  watch: {
    activityId: {
      handler(newval, oldval) {
        if (newval) {
          ActivityService.queryById(newval).then((res) => {
            this.data = res.data;
            if (this.type === "detail") {
              if (!this.data.imageUrl) {
                this.imageIsNone = false;
              }
            }
          })
        }
      },
      immediate: true,
    },
    type: {
      handler(newval, oldval) {
        if (newval === 'add') {
          this.diaTitle = '新增'
          this.disableOrNot = false
        }
        if (newval === 'edit') {
          this.diaTitle = '编辑'
          this.disableOrNot = false
        }
        if (newval === 'detail') {
          this.diaTitle = '详情'
          this.disableOrNot = true
        }
      },
      immediate: true,
    },
  },
  computed: {
    dialogVisb: {
      get() {
        return this.dialogFormVisible
      },
      set(v) {
        this.$emit('update:dialogFormVisible', v)
      }
    }
  },
  mounted() {
    this.getDic();
  },
  methods: {
    // save() {
    //   this.$refs['activityData'].validate((valid) => {
    //     if (valid) {
    //       if (this.type === 'add') {
    //         ActivityService.insertOrUpdate(this.data).then((res) => {
    //           if (res.state) {
    //             this.$message.success('新增成功')
    //             this.handleClose()
    //             this.$emit('refresh')
    //           }
    //         })
    //       }
    //       if (this.type === 'edit') {
    //         ActivityService.insertOrUpdate(this.data).then((res) => {
    //           if (res.state) {
    //             this.$message.success('修改成功')
    //             this.handleClose()
    //             this.$emit('refresh')
    //           }
    //         })
    //       }
    //     } else {
    //       return false;
    //     }
    //   })
    // },
    // 字典初始化
    getDic(){
      // 研究成果名称
      dictionary.getDicByCode("activity_photography").then(res => {
        if (res.state){
          this.activities = res.data;
        }
      })
    },
    upload(file) {
      window.open(this.baseUrl + file.imageUrl, '_blank')
    },
    handleRemove(file, fileList) {
    },
    //上传图片
    // beforeUpload(file) {
    //   const isLt2M = file.size / 1024 / 1024 < 2
    //   if (!isLt2M) {
    //     this.$message.error('上传图片大小不能超过 2MB!')
    //     return false;
    //   }
    //
    //   let form = new FormData()
    //   form.append("file", file);
    //   ActivityService.uploadImage(form).then(res => {
    //     const item = {
    //       imageUrl: res.data,
    //       name: res.data.match(/[^\/]+$/)[0],
    //       uid: nanoid()
    //     };
    //     this.imageList.push(item);
    //     this.data.imageUrl = res.data;
    //   });
    //   return false;
    // },
    handleClose() {
      this.data = {}
      this.dialogVisb = false
    },
  },
}
</script>
<style scoped>

</style>
