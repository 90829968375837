import request from '@/utils/request.js';

export default {

    searchListDevice(data) {
        return request({
            url:"/device/pageDevice",
            method:"post",
            data
        })
    },
    deleteDevice(ids) {
        return request({
            url:`/device/removeDevice?ids=${ids}`,
            method:"post"
        })
    },
    insertOrUpdateDevice(data) {
        return request({
            url:"/device/insertOrUpdateDevice",
            method:"post",
            data
        })
    },
    getByDeviceId(id){
        return request({
            url:`/device/getByDeviceId/${id}`,
            method:"get"
        })
    },
}