<template>
  <div>
    <el-dialog width="40%" :title="diaTitle" :visible.sync="dialogVisb" @close="handleClose" :close-on-click-modal="false">
      <el-form :model="data" label-width="80px" ref="competitionData" :rules="rules">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="比赛年份" prop="competitionYear">
              <div class="block">
                <el-date-picker
                    style="width: 100%"
                    :disabled="disableOrNot"
                    v-model="data.competitionYear"
                    type="year"
                    value-format="yyyy"
                    placeholder="请选择比赛年份">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="比赛名称" prop="competitionName">
              <el-input :disabled="disableOrNot" v-model="data.competitionName" placeholder="请输入比赛名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改人" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updatedBy"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改时间" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updationDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: right">
        <el-button @click="handleClose()">取消</el-button>
        <el-button v-if="!disableOrNot" type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import competitionService from '../../../../api/competition'

export default {
  name: 'AddOrModifyDialog',
  props: {
    type: String,
    competitionId: String,
    dialogFormVisible: Boolean,
  },
  data() {
    return {
      disableOrNot: false,
      diaTitle: '',
      data: {},
      rules: {
        competitionYear: [{
          required: true,
          message: '比赛年份不能为空',
          trigger: 'change'
        }],
        competitionName: [{
          required: true,
          message: '比赛名称不能为空',
          trigger: 'blur'
        }],
      }
    }
  },
  watch: {
    competitionId: {
      handler(newval, oldval) {
        if (newval) {
          competitionService.getById(newval).then((res) => {
            this.data = res.data;
            this.data.competitionYear = '' + this.data.competitionYear;
          })
        }
      },
      immediate: true,
    },
    type: {
      handler(newval, oldval) {
        if (newval === 'add') {
          this.diaTitle = '新增'
          this.disableOrNot = false
        }
        if (newval === 'edit') {
          this.diaTitle = '编辑'
          this.disableOrNot = false
        }
        if (newval === 'detail') {
          this.diaTitle = '详情'
          this.disableOrNot = true
        }
      },
      immediate: true,
    },
  },
  computed: {
    dialogVisb: {
      get() {
        return this.dialogFormVisible
      },
      set(v) {
        this.$emit('update:dialogFormVisible', v)
      }
    }
  },
  methods: {
    save() {
      this.$refs['competitionData'].validate((valid) => {
        if (valid) {
          if (this.type === 'add') {
            competitionService.insertOrUpdate(this.data).then((res) => {
              if (res.state) {
                this.$message.success('新增成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
          if (this.type === 'edit') {
            competitionService.insertOrUpdate(this.data).then((res) => {
              if (res.state) {
                this.$message.success('修改成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
        } else {
          return false;
        }
      })
    },
    handleClose() {
      this.data = {}
      this.dialogVisb = false
    },
  },
}
</script>
<style src="../../../../../node_modules/@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>
