import request from '@/utils/request.js';

export default {
    login(data){
        return request({
            url:"/user/login",
            method:"post",
            data
        })
    },
    getMessage(satoken){
        return request({
            url:`/user/getMessage?token=${satoken}`,
            method:"post",
        })
    },
    logout(){
        return request({
            url:"/user/logout",
            method:"post",
        })
    },

    checkActiveTimeout(){
        return request({
            url:'/user/checkActiveTimeout',
            method:"get",
        })
    }
}