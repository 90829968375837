import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import '@/assets/text/font.css'
import '@/assets/css/authority.css'
import '@/assets/css/login.css'
import '@/assets/css/global.css'
import '@/assets/css/style.css'
import '@/assets/css/global.css'
import ElementUI from 'element-ui';
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '../src/utils/permission'
Vue.use(ElementUI);

Vue.prototype.$message = Message
Vue.config.productionTip = false

import Carousel3d from 'vue-carousel-3d';
import store from "@/store";
//3d轮播图
Vue.use(Carousel3d);
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
