<template>
  <div class="main">
    <div class="intro_top">
      <span>团队负责人/</span>
      <em>TEAM LEADER</em>
    </div>
    <div class="picture">
        <div v-for="student in students" :key="student.userName" class="card" @click="toInfo(student.userName)">
          <img :src="baseUrl + student.imageUrl" alt=""/>
          <div class="text" style="margin: 10px;">
            <p style="font-size:20px;color: brown ;">{{ student.userName }} ({{ student.manageYear }})</p>
            <p style="font-size: 13px;">{{student.introduce}}</p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import MessageService from "@/api/message";
import { BASE_URL } from '@/utils/constant';

export default {
  data() {
    return {
      baseUrl: BASE_URL,
      students: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      MessageService.queryIsShowUser().then(res => {
        this.students = res.data.filter(item => item.roleId === "1003" && item.isManage === "1");
        this.students.sort((a, b) => {
          return a.manageYear - b.manageYear;
        });
      })
    },
    toInfo(userName) {
      this.$router.push({name: 'information', params: {userName: userName}});
    },
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 1200px;
  margin: 0 auto;
}

.card img {
  width: 320px;
  height: 400px;
  border-radius: 4px;
}

.card:hover {
  transform: scale(1.1);
  transition: 0.3s;
  cursor: pointer;
}

.picture {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  // background-color: aqua;
}

.card {
  text-align: center;
  height: max-content;
  width: max-content;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  display: inline-block;
  margin: 10px 18px;
  vertical-align: top;
}

.card .text {
  background-color: rgb(255, 255, 255);
  width: 300px;
  padding-left: 5px;
}

.card span {
  font-size: 12px;
  color: #bfbfbf;
  display: block;
  letter-spacing: 2px;
  padding: 30px 20px;
}
</style>

<style scoped>

.intro_top {
  margin-top: 30px;
  line-height: 38px;
  padding-right: 5px;
  padding-left: 5px;
  width: max-content;
  background-color: rgba(221, 237, 239, 0.8);
  border-radius: 5px;
}

.intro_top span {
  font-size: 28px;
  color: #1f2c5c;;
}

.intro_top em {
  font-size: 16px;
  color: #2d2d2d;
}


</style>
