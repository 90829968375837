<template>
  <div class="wareHouseBody">
    <div class="flex-column height100">
      <div class="searchBox">
        <el-form label-width="72px">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="活动类型">
                <el-select v-model="searchParams.params.type" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in types"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否展示">
                <el-select v-model="searchParams.params.isShow" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in YesOrNot"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24" type="flex" align="middle" style="margin: 0">
            <el-row :span="15">
              <el-button type="primary" @click="handleAdd()" v-btn-permission="'user_add'">新增</el-button>
              <el-button type="primary" @click="del()" v-btn-permission="'user_delete'" :disabled="disabledType">批量删除</el-button>
              <el-button type="primary" @click="load()">查询</el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
            </el-row>
          </el-row>
        </el-form>
      </div>

      <div class="tableData" style="height: 100%">
        <el-table
            ref="filterTable"
            :data="activityList"
            :show-overflow-tooltip="true"
            @selection-change="handleSelectionChange"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="type" label="活动类型" min-width="150"/>
          <el-table-column prop="updationDate" label="上传时间" min-width="150"/>
          <el-table-column label="是否展示" min-width="100">
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.isShow"
                  active-color="#13ce66"
                  active-value = "1"
                  inactive-value = "0"
                  @change="handleSwitchChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="viewItem(scope.row)">详情</el-button>
              <el-button @click="handleEdit(scope.row)" size="mini" v-btn-permission="'user_edit'">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
            style="text-align: right;"
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="this.searchParams.pageBean.pageNum"
            :page-size="this.searchParams.pageBean.pageSize"
            :page-sizes="pageSizes" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <AddOrModifyDialog v-if="dialogFormVisible" :dialogFormVisible.sync="dialogFormVisible" :activityId="activityId" :type="type" @refresh="load"/>
  </div>
</template>

<script>
import ActivityService from "../../../api/activity"
import AddOrModifyDialog from "@/components/Manage/Activity/components/AddOrModify";
import dictionary from "@/api/dictionary";

export default {
  name: 'Activity',
  components: {AddOrModifyDialog},
  data() {
    return {
      searchParams: {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          type: '',
          isShow: ''
        }
      },
      types: [],
      disabledType: true,
      pageSizes: [10, 20, 30, 50, 100], //可选择的一页多少条
      total: 0, //总条目数
      activityList: [],
      multipleSelection: [],
      dialogFormVisible: false,
      activityId: '',
      type: '',
      YesOrNot: [
        {
          name: '是',
          value: "1"
        },
        {
          name: '否',
          value: "0"
        },
      ],
    }
  },
  watch: {
    multipleSelection(val) {
      if (val.length > 0) {
        this.disabledType = false;
      } else {
        this.disabledType = true;
      }
    },
  },
  mounted() {
    this.load();
    this.getDic();
  },
  methods: {
    load() {
      ActivityService.searchList(this.searchParams).then(res => {
          this.activityList = res.rows;
          this.total = res.total;
      })
    },
    // 字典初始化
    getDic(){
      // 研究成果名称
      dictionary.getDicByCode("activity_photography").then(res => {
        if (res.state){
          this.types = res.data;
        }
      })
    },
    del() {
      let ids = this.multipleSelection.map((item) => item.id).join(",");
      let self = this;
      self
          .$confirm("此操作将删除已选择数据, 是否继续?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            ActivityService.delete(ids)
                .then((res) => {
                  if (res.state) {
                    self.$message.success(res.message);
                  } else {
                    self.$message.error(res.message);
                  }
                }).finally(() => {
              self.load();
            });
          });
    },
    reset() {
      this.searchParams = {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          type: '',
          isShow: ''
        },
      }
      this.load()
    },
    handleSwitchChange(row){
      let activity = {
        id : row.id,
        isShow : row.isShow,
      };
      ActivityService.insertOrUpdate(activity);
    },
    handleAdd() {
      this.type = "add";
      this.activityId = undefined;
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      this.type = 'edit';
      this.activityId = row.id;
      this.dialogFormVisible = true
    },
    viewItem(row) {
      this.type = 'detail';
      this.activityId = row.id;
      this.dialogFormVisible = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      this.searchParams.pageBean.pageNum = val;
      this.load();
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      this.searchParams.pageBean.pageSize = val;
      this.load();
    },
  }
}
</script>

<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}

.searchBox {
  margin-top: 10px;
  padding: 20px;
}

.tableData {
//margin-top: 20px;
  border-radius: 20px;
  border: thin solid #eaebec;
  width: 98%;
  margin-left: 1%;
}
</style>