<template>
  <div style="height: 100%">
    <el-container style="height: 100%;">
      <el-aside style="width: auto;background-color: #079ec9;">
        <Aside></Aside>
      </el-aside>
      <el-container style="background-color: #fafafa">
        <el-header style="text-align: right; font-size: 10px;height:6%;background-color: white;">
          <el-dropdown trigger="click" style="cursor: pointer">
            <i class="el-icon-setting" style="margin-top:15px;font-size: 20px;margin-right: 15px;color:#000"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="logout()">退出登录</el-dropdown-item>
              <el-dropdown-item @click.native="back()">返回首页</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span style="margin-top:15px;font-size: 20px;">{{ user.userName }}</span>
          <el-avatar v-if="!isImage" class="avatar_user" icon='el-icon-user-solid'></el-avatar>
          <el-avatar class="avatar_user" v-if="isImage" :size="'medium'" :fit="'fill'" @error="errorHandler"
                     :style="{ 'background-image': 'url(' + baseUrl + imageUrl + ')', 'background-size': 'cover' }"/>
        </el-header>
        <el-main style="padding: 0;height: 100%">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import Aside from "./Aside";
import LoginService from "../../api/login"
import MessageService from "@/api/message";

export default {
  name: "Main",
  components: {Aside},
  data() {
    return {
      // baseUrl: 'http://122.51.67.189:8090',
      baseUrl: 'https://www.yuetongroad.com',
      user: {},
      userDetailId: '',
      imageUrl: '',
      isImage: false,
    }
  },
  mounted() {
    this.getMessage();
  },
  methods: {
    getMessage() {
      const satoken = localStorage.getItem('satoken');
      LoginService.getMessage(satoken).then(res => {
        this.user = res.data;
        this.userDetailId = res.data.userDetailId;
        MessageService.queryDetail(this.userDetailId).then(res => {
          if (res.state && res.data.imageUrl) {
            this.imageUrl = res.data.imageUrl;
            this.isImage = true;
          }
        })
        localStorage.setItem('userName', res.data.userName);
        localStorage.setItem('userId', res.data.id);
        localStorage.setItem('userDetailId', res.data.userDetailId);
        localStorage.setItem('roleId', res.data.roleId);
      })
    },
    logout() {
      LoginService.logout().then(res => {
        if (res.state) {
          localStorage.removeItem('satoken');
          this.$router.push("/login");
          this.$message.success(res.message);
        }
      })
    },
    back() {
      this.$router.push("/homePage");
      this.$message.success("操作成功");
    },
    errorHandler() {
      return true
    }
  }
}
</script>
<style>
.avatar_user {
  margin-top:-10px;
  vertical-align: middle;
  margin-left: 15px;
  width: 35px;
  height: 35px;
}
</style>