<template>
  <el-container class="home-container">

    <!-- 头部区域 -->
    <el-header style="height: 90px; padding:5px 0 0 0;">
      <!--logo-->
      <div style="background-color: black; width:700px">
        <img src="../assets/img/1.1.png" alt="" width="200px" height="80px" style="margin-top:0;margin-left: 70px">
      </div>
      <!-- 头部 -->
      <div>
        <img src="" alt="">
      </div>
      <!-- 导航栏 -->
      <el-menu class="top" background-color="black" mode="horizontal" text-color="white" active-text-color="white"
               style="height: 92%; width:100%;  display:flex;
  justify-content:center ">
        <el-menu-item index="1" @click="routerHomePage()"
                      style=" margin-right:80px;font-size: 18px;font-weight: bolder">首页
        </el-menu-item>
        <el-submenu index="2" style="width: 300px; text-align: center;font-size: medium">
          <div slot="title" style="font-size: 18px">团队风采</div>
          <el-menu-item index="2-1" @click="routershow1()" style="width: 300px;">团队负责人</el-menu-item>
          <el-menu-item index="2-2" @click="routershow2()">优秀成员</el-menu-item>
          <el-menu-item index="2-3" @click="routershow3()">荣誉证书</el-menu-item>
          <el-menu-item index="2-3" @click="routershow4()">作品展示</el-menu-item>
          <el-menu-item index="2-4" @click="routershow5()">科研成果</el-menu-item>
        </el-submenu>
        <el-submenu index="3" @click="routerActivity" style="width: 300px; text-align: center;font-size: medium">
          <div slot="title" style="font-size: 18px">活动摄影</div>
          <el-menu-item index="3-1" @click="routerActivity1()" style="width: 300px;">比赛活动</el-menu-item>
          <el-menu-item index="3-2" @click="routerActivity2()">日常科研</el-menu-item>
          <el-menu-item index="3-3" @click="routerActivity3()">团队合照</el-menu-item>
        </el-submenu>
        <el-menu-item index="4" @click="routerContact2()"
                      style="font-size: 18px; margin-left: 30px; font-weight: bolder">联系我们
        </el-menu-item>
        <!--
        <el-menu-item index="5" @click="routerLogin" style="font-size: medium;margin-left:120px">登陆/注册</el-menu-item>
        -->
      </el-menu>
      <div>
        <div @click="routerLogin" class="login" style="font-size: 18px;margin-top: 11px">登录</div>
        <div class="login" style="font-size: 18px;margin-top: 11px"></div>
      </div>
    </el-header>
    <!-- 页面主体区域 -->
    <el-main style="padding: 0">
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import LoginService from "../api/login"

export default {
  data() {
    return {}
  },
  methods: {
    routerShow() {
      this.$router.push('/show');
    },
    routerHomePage() {
      this.$router.push('/homepage');
    },
    routerActivity() {
      this.$router.push('/activityHome');
    },
    routerContact() {
      this.$router.push('/contact');
    },
    routerLogin() {
      const satoken = localStorage.getItem('satoken');
      if (satoken) {
        LoginService.checkActiveTimeout().then(res => {
          if (res.code === 200) {
            this.$router.push("/message");
          } else {
            localStorage.removeItem('satoken');
            this.$router.push("/login");
          }
        })
      } else {
        this.$router.push("/login");
      }
    },
    routershow1() {
      this.$router.push('/first');
    },
    routershow2() {
      this.$router.push('/second');
    },
    routershow3() {
      this.$router.push('/third');
    },
    routershow4() {
      this.$router.push('/fourth');
    },
    routershow5() {
      this.$router.push('/fifth');
    },
    routerActivity1() {
      this.$router.push('/competition')
    },
    routerActivity2() {
      this.$router.push('/daily')
    },
    routerActivity3() {
      this.$router.push('/gphoto')
    },
    routerContact1() {
      this.$router.push('/way')
    },
    routerContact2() {
      this.$router.push('/map')
    },
  }

};
</script>

<style lang="less" scoped>
.home-container {
  background-color: rgba(255, 255, 255, 0.9);
  height: 100%;
}

.el-menu-item:hover {
  /*
  background-color: rgba(52, 52, 52, 0.877)!important;
  color:rgb(255, 255, 255) !important;
  border-radius: 3px;
  box-shadow: inset 0px 0px 16px 0px rgb(255, 255, 255);
  */

  background-color: rgba(206, 206, 206, 0.932) !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 4px;
  box-shadow: inset 0px 0px 18px 3px rgb(0, 0, 0);
}

.el-menu-item {
  text-align: center;
  border: 1px black;
}

.top {
  padding-top: 5px;

  ::v-deep .el-submenu__title {
    .el-icon-arrow-down {
      display: none;
    }
  }

}

.el-header {
  background-color: black;
  display: flex;
  justify-content: center;
}


</style>

<style scoped>
.el-menu--horizontal > .el-submenu .el-submenu__title {
  margin-right: 60px;
  font-size: medium;
  border: none;
}

.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(206, 206, 206, 0.932) !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 4px;
  box-shadow: inset 0px 0px 18px 3px rgb(0, 0, 0);
}

.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

.el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}


.login {
  text-align: center;
  margin-left: 10px;
  margin-right: 60px;
  margin-top: 10px;
  width: 90px;
  font-size: medium;

  background-color: black;
  --c: #ffffff; /* the color  */
  --h: 2.5em; /* the height */
  line-height: var(--h);
  background: linear-gradient(var(--c) 0 0) no-repeat calc(200% - var(--_p, 0%)) 100%/200% var(--_p, .08em);
  color: #0000;
  overflow: hidden;
  text-shadow: 0 calc(-1 * var(--_t, 0em)) var(--c),
  0 calc(var(--h) - var(--_t, 0em)) #000000;
  transition: .3s calc(.3s - var(--_s, 0s)), background-position .3s calc(.3s - var(--_s, 0s));
}

.login:hover {
  --_t: var(--h);
  --_p: 100%;
  --_s: .3s;
  cursor: pointer;
}

</style>