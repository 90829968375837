import request from '@/utils/request.js';

export default {
    // 查询信息
    queryDetail(id) {
        return request({
            url: `/user/queryDetailById?id=${id}`,
            method: "post",
        })
    },
    updateDetail(data) {
        return request({
            url: "/user/detail/insertOrUpdate",
            method: "post",
            data
        })
    },
    change(data) {
        return request({
            url: "/user/updatePwd",
            method: "post",
            data
        })
    },
    queryIsShowUser() {
        return request({
            url: '/user/queryIsShowUser',
            method: "get",
        })
    },
    uploadFile(data) {
        return request({
            type: "file",
            url: "/user/upload",
            method: "post",
            data
        })
    }
}