import request from '@/utils/request.js';

export default {
    searchList(data) {
        return request({
            url:"/project/page",
            method:"post",
            data
        })
    },
    export(data) {
        return request({
            url:"/project/export",
            method:"post",
            responseType:'blob',
            data
        })
    },
    getById(id){
        return request({
            url:`/project/getById/${id}`,
            method:"get"
        })
    },
    delete(ids) {
        return request({
            url:`/project/remove?ids=${ids}`,
            method:"post"
        })
    },
    searchUsers() {
        return request({
            url:"/user/searchAllUsers",
            method:"get"
        })
    },
    insertOrUpdate(data) {
        return request({
            url:"/project/insertOrUpdate",
            method:"post",
            data
        })
    },
    searchAllYears() {
        return request({
            url:"/project/searchAllYears",
            method:"get"
        })
    },
    searchByYear(year){
        return request({
            url:`/project/searchByYear?year=${year}`,
            method:"post"
        })
    },
    searchProjectName(){
        return request({
            url:"/project/searchProjectName",
            method:"get"
        })
    },
    uploadFile(data) {
        return request({
            type: "file",
            url: "/project/upload",
            method: "post",
            data
        })
    },
    queryProjectImages() {
        return request({
            url: "/project/queryProjectImages",
            method: "get"
        })
    },
    uploadImage(data) {
        return request({
            type: "file",
            url: "/project/uploadImage",
            method: "post",
            data
        })
    }
}
