<template>
  <div>
    <!--	<div class="intro_top">-->
    <!--       <span>联系方式/</span>-->
    <!--       <em>CONTACT</em>-->
    <!--    </div>-->
    <!--	<div class="contact">-->
    <!--		<div class="card"  >-->
    <!--		@click="show1 = !show1" />-->
    <!--		</div>-->
    <!--		<div style="width: 400px;-->
    <!--			height: 200px;-->
    <!--			background-color: aqua;-->
    <!--			display: flex;-->
    <!--			flex-wrap: nowrap;">-->
    <!--			<el-collapse-transition>-->
    <!--				<div v-show="show1">-->
    <!--					<div class="transition-box">el-collapse-transition</div>-->
    <!--				</div>-->

    <!--			</el-collapse-transition>-->
    <!--			<el-collapse-transition>-->
    <!--				<div v-show="show2">-->
    <!--					<div class="transition-box">el-collapse-transition</div>-->
    <!--				</div>-->

    <!--			</el-collapse-transition>-->

    <!--		</div>-->

    <!--		<div class="card">-->
    <!---->
    <!--		</div>-->
    <!--	</div>-->
    <div class="contact">
      <div class="banner">
        <img src="@/assets/img/contact.jpg">
        <div class="content_box">
          <div class="middle">
            <div>
              <h1>联系我们</h1>
              <div class="p1">
                对月桐路创新团队有任何疑问，请通过这些渠道与我们联系
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="feedback">
            <div class="textbox">
              <h2>网站意见反馈</h2>
              <p>对我们的网站有任何问题或建议，请联系我们留言。</p>
            </div>
          </div>
          <div class="way">
            <div class="textbox">
              <h2>联系方式</h2>
              <div class="icon">
                <p>联系邮箱：1072141599@qq.com</p>
              </div>
              <div class="icon">
                <p>联系群聊：757016469</p>
              </div>
              <div class="icon">
                <p>联系地址：南京市江宁区淳化街道弘景大道99号金陵科技学院江宁校区</p>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="baidumap" id="allmap">-->
<!--        地图导航-->
<!--      </div>-->
    </div>

     <!-- 底部区域 -->
    <div class="bottom" id="bottomBox">
      <div class="footer">
        <div class="boxf">
          <div>
            <h3>团队介绍</h3>
            <p>团队简介</p>
            <p>导师简介</p>
          </div>
          <div>
            <h3>团队风采</h3>
            <p>团队代表</p>
            <p>成员风采</p>
          </div>
            <img src="@/assets/img/1.1.png" alt="Logo" class="logof">
          <div>
            <h3>科研活动</h3>
            <p>荣誉证书</p>
            <p>作品展示</p>
          </div>
          <div>
            <h3>关于月桐路团队</h3>
            <p>服务：757016469</p>
            <p>咨询：1072141599@qq.com</p>
          </div>
        </div>
      </div>
      <div class="footerr">
        <p>Copyright©2023-2023&nbsp; 月桐路科技创新团队&nbsp; All Right Reserved <a href="https://beian.miit.gov.cn/" target="_blank">&nbsp; 苏ICP备2023052797号-1 </a>&nbsp;
          <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank"><img style="width: 17px;height: 17px;margin-bottom: -4px" src="@/assets/img/police.png"> 苏公网安备32011502011945号</a>
        </p>
      </div>

    </div>
  </div>


</template>

<script>
export default {
  data() {
    return {
      position_NKYYGS: {
        Name: '金陵科技学院-江宁校区',
        address: '江苏省南京市江宁区弘景大道99号',
        bdLNG: 118.902977,
        bdLAT: 31.913705
      },
      show1: true,
      show2: true

    }
  },
  mounted() {
    this.baiduMap();
    window.addEventListener('scroll', this.handleScroll, true)
    this.handleScroll()
  },
  methods: {
    baiduMap() {
      var map = new BMap.Map('allmap'); // 创建地图实例
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      map.addControl(new BMap.NavigationControl());
      map.addControl(new BMap.ScaleControl());
      map.addControl(new BMap.OverviewMapControl());
      map.addControl(new BMap.MapTypeControl());
      // map.setMapStyle({ style: 'midnight' }) //地图风格


      map.clearOverlays();
      //将数据遍历
      //自定义信息窗口内容
      var sContent =
          '<div style="width:300px;"><p style="font-size:16px;font-weight:bold;margin-top: 10px;color:#D07852">' +
          this.position_NKYYGS.Name +
          '</p><p style="font-size:13px;margin: 5px 0;">地址：' +
          this.position_NKYYGS.address +
          // '</p><p style="font-size:13px;margin: 5px 0;">已打卡数：' +
          // this.position_NKYYGS.phoneNumber +
          '</p><p style="font-size:13px;margin: 5px 0;">坐标：' +
          this.position_NKYYGS.bdLNG +
          ',' +
          this.position_NKYYGS.bdLAT;
      ('</div>');


      var point = new BMap.Point(this.position_NKYYGS.bdLNG, this.position_NKYYGS.bdLAT); // 创建点坐标


      var marker = new BMap.Marker(point);

      map.addOverlay(marker);

      map.centerAndZoom(point, 15); // 初始化地图，设置中心点坐标和地图级别

      // //点击图标时候调用信息窗口
      var infoWindow = new BMap.InfoWindow(sContent);
      marker.openInfoWindow(infoWindow);

      marker.addEventListener('click', function () {
        this.openInfoWindow(infoWindow);
      })

    },
    handleScroll() {
      const triggerBottom = window.innerHeight / 5 * 4
      const bottomBox = document.getElementById('bottomBox')

      //底部栏
      if (bottomBox) {
        const bottomTop = bottomBox.getBoundingClientRect().top
        if(bottomTop < triggerBottom) {
            bottomBox.classList.add('show')
          } else {
            bottomBox.classList.remove('show')
          }
      } else {}
    }
  }
}
</script>

<style scoped>

.contact {
  width: 100%;
  display: inline-block;
}

.banner {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.banner img {
  border-style: none;
  width: 100%;
  height: auto;
  float: left;
  vertical-align: middle;
  max-width: 100%;
}

.content_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  color: white;
}


.middle {
  display: table;
  height: 100%;
  width: 100%;
}

.middle > div {
  vertical-align: middle;
  display: table-cell;
  height: 100%;
  min-height: 100%;
  float: none !important;
}

.content_box h1 {
  max-width: 80%;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 auto;
  font-size: 2.5em;
  color: #fff;
  padding-bottom: 0;
}

.content_box .p1 {
  margin: 0 auto;
  margin-top: 33px;
  font-size: 1em;
  line-height: 1.95em;
  color: #ffffff;
  text-align: center;
  padding: 0 20px;
  max-width: 50%;
  margin-bottom: 30px !important;
}

.container {
  text-align: center;
  background-color: #18c180;
  margin-bottom: 50px !important;
  margin-right: auto;
  margin-left: auto;
  width: 85.652%;
  height: max-content;
  padding-left: 45px;
  padding-right: 45px;
  margin-top: 30px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row::before {
  content: " ";
  position: relative;
  display: table;
  text-align: center;
}

.feedback {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
  float: left;
  background-color: rgb(255, 255, 255);
}

.textbox {
  text-align: left;
  margin-bottom: 70px;
  padding-top: 25px;
}

.textbox h2 {
  color: #333;
  text-align: left;
  position: relative;
  padding: 0;
  padding-bottom: 8px;
  display: inline-block;
  margin: 0;
  float: none !important;
  max-width: 80%;
  margin-bottom: 20px;
  width: 190px;
  height: 30px;
  font-size: 1.7em;
  font-weight: bold;
}

.textbox h2::after {
  content: '';
  width: 30%;
  height: 2px;
  background-color: #c7000b;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  min-width: 45px;
  transform: translateX(0);
}

.textbox p {
  margin-top: 3px;
  margin: 0;
  color: #666;
  font-size: 1em;
  line-height: 1.95em;
}

.way {
  position: relative;
  min-height: 1px;
  height: max-content;
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
  float: left;
  background-color: #ffffff;
}

.icon {
  display: flex;
  background-color: rgb(255, 255, 255);
}

.icon img {
  width: 90px;
  height: auto;
  border-radius: 20px;
  margin-right: 50px;
}

.icon img:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.3s;
}

.baidumap {

  width: 1200px;
  height: 500px;
  margin: 10px auto;
  border: 10px solid rgb(0, 0, 0);
  border-radius: 15px;

}

/* 去除百度地图版权那行字 和 百度logo */
/deep/ .baidumap > .BMap_cpyCtrl {
  display: none !important;
}

/deep/ .baidumap > .anchorBL {
  display: none !important;
}

.intro_top {
  width: 1200px;
  margin-left: 200px;
  line-height: 38px;
  padding-right: 5px;
  padding-left: 5px;
  width: max-content;
  background-color: rgba(221, 237, 239, 0.6);
  border-radius: 5px;
}

.intro_top span {
  font-size: 28px;
  color: #1f2c5c;;
}

.intro_top em {
  font-size: 16px;
  color: #2d2d2d;
}

.card img {
  width: 100%;
  border-radius: 30px;
}

.card img:hover {
  transform: scale(1.1);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 30px;
}

/*.contact {*/
/*  margin: 20px 200px;*/
/*  display: flex;*/
/*  flex-wrap: nowrap;*/
/*  justify-content: space-around;*/
/*}*/

/*.card span {*/
/*  font-size: 12px;*/
/*  color: #bfbfbf;*/
/*  display: block;*/
/*  letter-spacing: 2px;*/
/*  padding: 30px 20px;*/
/*}*/

/* 底部 */
.bottomTop{
  height: 100px;
}
.footer{
  padding: 20px 10% 10px;
  background-color:black;
  color: #fff;
  height: 130px;
}
.boxf {
  height:80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size:small;
  line-height: 1.875;
}
.footerr{
  padding-top: 1px;
  width: 100%;
  height: 40px;
  background-color:  #2c2c2c;
  font-size:10px;
  color: #fff;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
}

.logof{
  width: 260px;
  height: 110px;
  padding-left: 40px;
}

/* 底部滑动效果 */
.bottom {
  transform: translate3d(0,-100px,0);
  transition: transform 0.5s ease;
  opacity: 0;
  transition-property: opacity,transform;
}

.show {
  opacity: 1;
  transform: translateX(0);
}

</style>