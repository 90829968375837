<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-View></router-View>
  </div>
</template>

<script>
import { filterRoute } from './router/index'
import router from './router/index'
import routerList from './router/routingArray'

export default (await import('vue')).defineComponent({
  name: 'app',
  created() {
    var splitAdd = localStorage.getItem("role").split(",");
    const newRouterData = filterRoute(routerList, splitAdd);
    this.$router.options.routes.push(...newRouterData);
    router.addRoutes(newRouterData);
  },
})
</script>

<style>

</style>
