<template>
  <div>
      <el-main>
        <router-view></router-view>
      </el-main>

       <!-- 底部区域 -->
    <div class="bottom" id="bottomBox">
      <div class="footer">
        <div class="boxf">
          <div>
            <h3>团队介绍</h3>
            <p>团队简介</p>
            <p>导师简介</p>
          </div>
          <div>
            <h3>团队风采</h3>
            <p>团队代表</p>
            <p>成员风采</p>
          </div>
            <img src="@/assets/img/1.1.png" alt="Logo" class="logof">
          <div>
            <h3>科研活动</h3>
            <p>荣誉证书</p>
            <p>作品展示</p>
          </div>
          <div>
            <h3>关于月桐路团队</h3>
            <p>服务：757016469</p>
            <p>咨询：1072141599@qq.com</p>
          </div>
        </div>
      </div>
      <div class="footerr">
        <p>Copyright©2023-2023&nbsp; 月桐路科技创新团队&nbsp; All Right Reserved <a href="https://beian.miit.gov.cn/" target="_blank">&nbsp; 苏ICP备2023052797号-1 </a>&nbsp;
          <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank"><img style="width: 17px;height: 17px;margin-bottom: -4px" src="@/assets/img/police.png"> 苏公网安备32011502011945号</a>
        </p>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerHeight: 200,
    }
  },
  mounted() {
    this.setSize()
    window.addEventListener(
      'resize',
      () => {
        this.setSize()
      },
      false
    )
    window.addEventListener('scroll', this.handleScroll, true)
    this.handleScroll()
  },
  methods: {
    handleScroll() {
      const triggerBottom = window.innerHeight / 5 * 4
      const bottomBox = document.getElementById('bottomBox')

      //底部栏
      if (bottomBox) {
        const bottomTop = bottomBox.getBoundingClientRect().top
        if(bottomTop < triggerBottom) {
            bottomBox.classList.add('show')
          } else {
            bottomBox.classList.remove('show')
          }
      } else {}
    }
  }
}
</script>

<style lang="less" scoped>
.bannerimg {
  width: 700px;
  height: inherit;
}
.sub-header {
  margin: 0 auto;
}

/* 底部 */
.bottomTop{

height: 100px;
}
.footer{
  padding: 20px 10% 10px;
  background-color:black;
  color: #fff;
  height: 130px;
}
.boxf {
  height:80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size:small;
  line-height: 1.875;
}
.footerr{
  padding-top: 1px;
  width: 100%;
  height: 40px;
  background-color:  #2c2c2c;
  font-size:10px;
  color: #fff;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
}

.logof{
  width: 260px;
  height: 110px;
  padding-left: 40px;
}

/* 底部滑动效果 */
.bottom {
  transform: translate3d(0,-100px,0);
  transition: transform 0.5s ease;
  opacity: 0;
  transition-property: opacity,transform;
}

.show {
  opacity: 1;
  transform: translateX(0);
}

</style>
