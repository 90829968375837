<template>
  <div class="info">
    <div class="back">
      <el-page-header style="color: white;" @back="back">
      </el-page-header>
    </div>
    <div class="content">
      <div class="picture">
        <img v-if="isImage" :src="baseUrl + userInfo.imageUrl"
             @click="upload(userInfo.imageUrl)" alt=""/>
      </div>
      <div class="tabs">
        <el-tabs :model="userInfo" v-model="currentTab">
          <el-tab-pane class="userInfo-tab" label="个人信息" name="first">
            <ul>
              <li><span>姓名：{{ userInfo.userName }}</span></li>
              <li><span>民族：{{ userInfo.nation }}</span></li>
              <li><span>年龄：{{ userInfo.age }}</span></li>
              <li><span>籍贯：{{ userInfo.nativePlace }}</span></li>
              <li><span>入学年份：{{ userInfo.entryYear }}</span></li>
              <li><span>专业：{{ userInfo.profession }}</span></li>
              <li><span>职务：{{ userInfo.job }}</span></li>
              <li id="company"><span>工作公司：{{ userInfo.company }}</span></li>
              <li id="score"><span>考研分数：{{ userInfo.score }}</span></li>
              <li id="institution"><span>考研院校：{{ userInfo.institution }}</span></li>
            </ul>
          </el-tab-pane>
          <el-tab-pane v-if="competition" label="竞赛荣誉" name="second">
            <el-card class="card_user">
              <div v-html="userInfo.competition"></div>
            </el-card>
          </el-tab-pane>
          <el-tab-pane v-if="campusExperience" label="校园经历" name="third">
            <el-card class="card_user">
              <div v-html="userInfo.campusExperience"></div>
            </el-card>
          </el-tab-pane>
          <el-tab-pane v-if="message" label="个人寄语" name="fourth">
            <el-card class="card_user">
              <div v-html="userInfo.message"></div>
            </el-card>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from '@/utils/constant';

export default {
  name: 'Information',
  data() {
    return {
      userInfo: {},
      userName: '',
      currentTab: 'first',
      competition: true,
      campusExperience: true,
      message: true,
      baseUrl: BASE_URL,
      isImage: false
    }
  },
  mounted() {
    this.userName = this.$route.params.userName;
    this.init();
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    upload(pic) {
      window.open(this.baseUrl + pic, '_blank')
    },
    init() {
      const students = JSON.parse(localStorage.getItem("userInfo"));
      this.userInfo = students.find(student => student.userName === this.userName);
      this.isImage = this.userInfo.imageUrl;
      this.userInfo.age = 2024 - this.userInfo.age;
      setTimeout(() => {
        if (!this.userInfo.company) {
          let company = document.getElementById("company");
          company.style.display = "none";
        }
        if (!this.userInfo.score) {
          let score = document.getElementById("score");
          score.style.display = "none";
        }
        if (!this.userInfo.institution) {
          let institution = document.getElementById("institution");
          institution.style.display = "none";
        }
        if (!this.userInfo.campusExperience) {
          this.campusExperience = false;
        }
        if (!this.userInfo.competition) {
          this.competition = false;
        }
      }, 1);
    }
  }
}
</script>

<style scoped>
.info {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: rgb(4, 75, 75);
  padding-top: 60px;
  background-image: url(@/assets/img/box2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.userInfo-tab {
  color: white;
  margin-top: 35px;
}

.content {
  width: 1300px;
  height: 600px;
  margin: 0 auto;
  background-color: rgba(88, 95, 115, 0.5);
  padding-top: 35px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.card_user {
  background-color: transparent;
  border-color: transparent;
  color: white;
  font-weight: bold;
  line-height: 26px;
  overflow-y: auto;
}

.picture {
  margin-top: 35px;
  margin-left: 70px;
  height: 268px;
  width: 200px;
  background-color: blueviolet;
  margin-right: 80px;
}

.picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.tabs {
  margin-top: 10px;
  height: 550px;
  width: 800px;
}

::v-deep .el-page-header__title {
  color: white;
  font-size: 16px;
}

::v-deep .el-tabs__item {
  color: white;
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
  width: 200px;
  text-align: center;
}

::v-deep .el-tabs__item.is-active {
  color: #409EFF;
}

.back:hover {
  transform: scale(1.3);
  transition: 0.5s;
}

::v-deep .el-page-header__title:hover {
  color: #409EFF;
}

::v-deep .el-icon-back:hover {
  color: #409EFF;
}

::v-deep .el-page-header__left::after {
  height: 0;
}


.tabs ul li {
  font-size: 24px;
  line-height: 26px;
  margin-left: 25px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  font-family: 宋体;
}

.back {
  width: max-content;
  position: relative;
  left: 30px;
  bottom: 25px;
}

.text {
  margin-top: 20px;
  text-indent: 2em;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  color: white;
  font-family: cursive;
}


h3 {
  font-size: 24px;
  font-weight: 500;
}


</style>