<template>
  <div class="wareHouseBody">
    <div class="flex-column height100">
      <div class="searchBox">
        <el-form label-width="150px">
          <el-row :gutter="24">
            <el-col :span="7">
              <el-form-item label="身份" class="role">
                <el-select v-model="searchParams.params.roleId" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in roles"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="姓名" class="name">
                <el-input v-model="searchParams.params.userName" placeholder="请输入用户名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="入学年份">
                <el-select v-model="searchParams.params.entryYear" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in years"
                      :key="item"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="7">
              <el-form-item label="学院" class="role">
                <el-select v-model="searchParams.params.academy" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in academys"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="专业" class="name">
                <el-select v-model="searchParams.params.profession" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in professions"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="团队职务">
                <el-select v-model="searchParams.params.job" style="width: 100%;">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in jobs"
                      :key="item"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="7">
              <el-form-item label="是否为团队成员" class="role">
                <el-select v-model="searchParams.params.isBelong" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in YesOrNot"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="是否启用" class="name">
                <el-select v-model="searchParams.params.enableFlag" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in YesOrNot"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否展示">
                <el-select v-model="searchParams.params.isShow" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in YesOrNot"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="7">
              <el-form-item label="是否团队负责人" class="name">
                <el-select v-model="searchParams.params.isManage" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in YesOrNot"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24" type="flex" align="middle" style="margin-left: 15px">
            <el-col :span="15">
              <el-button type="primary" @click="handleAdd()" v-btn-permission="'user_add'">新增</el-button>
              <el-button type="primary" @click="exportFile()" :loading="exportLoading">
                导出
              </el-button>
              <el-button type="primary" @click="del()" v-btn-permission="'user_delete'" :disabled="disabledType">
                批量删除
              </el-button>
              <el-button type="primary" @click="load(1);">查询</el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="tableData" style="height: 80%">
        <el-table
            :data="tableData"
            :show-overflow-tooltip="true"
            @selection-change="handleSelectionChange"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="userName" label="姓名" min-width="100"/>
          <el-table-column :formatter="convertRole" prop="roleId" label="身份" min-width="100"/>
          <el-table-column prop="academy" label="学院" min-width="200"/>
          <el-table-column prop="profession" label="专业" min-width="100"/>
          <el-table-column prop="entryYear" label="入学年份" min-width="100"/>
          <el-table-column prop="job" label="团队职务" min-width="100"/>
          <el-table-column label="是否为团队成员" min-width="120">
            <template slot-scope="scope">
              <el-switch
                  v-if="scope.row.roleId === '1003'"
                  v-model="scope.row.isBelong"
                  active-color="#13ce66"
                  active-value = "1"
                  inactive-value = "0"
                  @change="handleSwitchChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="是否启用" min-width="100">
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.enableFlag"
                  active-color="#13ce66"
                  active-value = "1"
                  inactive-value = "0"
                  @change="handleSwitchChange2(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="是否展示" min-width="100">
            <template slot-scope="scope" v-if="scope.row.roleId !== '1001' ">
              <el-switch
                  v-model="scope.row.isShow"
                  active-color="#13ce66"
                  active-value = "1"
                  inactive-value = "0"
                  @change="handleSwitchChange3(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="是否为团队负责人" min-width="100">
            <template slot-scope="scope" v-if="scope.row.roleId === '1003' ">
              <el-switch
                  v-model="scope.row.isManage"
                  active-color="#13ce66"
                  active-value = "1"
                  inactive-value = "0"
                  @change="handleSwitchChange4(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="密码修改" min-width="100" v-if="isChange">
            <template slot-scope="scope">
              <el-button v-if="scope.row.roleId === '1003' || (scope.row.roleId === '1002' && isAdmin)" type="text" @click="changePwd(scope.row)">修改</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" align="center">
            <template slot-scope="scope" v-if="scope.row.roleId === '1003' || (scope.row.roleId === '1002' && isAdmin)">
              <el-button type="text" @click="viewItem(scope.row)">查看</el-button>
              <el-button @click="handleEdit(scope.row)" size="mini" v-btn-permission="'user_edit'">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
            style="text-align: right;"
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="this.searchParams.pageBean.pageNum"
            :page-size="this.searchParams.pageBean.pageSize"
            :page-sizes="pageSizes" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="查看" :visible.sync="dialogFormVisible_view" v-if="dialogFormVisible_view" width="80%"
               :close-on-click-modal="false">
      <Message :userDetailId="userDetailId" :userName="userName" :isShowEdit="isShowEdit" :isStudent="isStudent"/>
    </el-dialog>
    <el-dialog title="编辑" :visible.sync="dialogFormVisible_change" v-if="dialogFormVisible_change" width="80%"
               :close-on-click-modal="false">
      <Message :userDetailId="userDetailId" :userName="userName" :isShowEdit="isShowEdit" :isStudent="isStudent"/>
    </el-dialog>
    <AddOrModifyDialog v-if="dialogFormVisible" :dialogFormVisible.sync="dialogFormVisible" :data="addData" @refresh="load"/>
    <el-dialog title="修改密码" :visible.sync="dialogFormVisibleChange" v-if="dialogFormVisibleChange" width="80%" :close-on-click-modal="false">
      <Change :userId="userId"></Change>
    </el-dialog>
  </div>
</template>


<script>
import manageService from "@/api/manage";
import {processDataFlow} from "@/utils";
import Message from "@/components/Manage/Message"
import MessageService from "@/api/message"
import AddOrModifyDialog from "@/components/Manage/UserManage/components/AddOrModify"
import Change from "@/components/Manage/Change";
import dictionary from "@/api/dictionary";

export default {
  name: 'Manage',
  components: {AddOrModifyDialog, Message,Change},
  data() {
    return {
      addData: {},
      total: 0, //总条目数
      tableData: [],
      multipleSelection: [],
      isShowEdit: '',
      isStudent: '',
      userDetailId: '',
      userName: '',
      userId: '',
      isChange: false,
      isAdmin: false,
      pageSizes: [10, 20, 30, 50, 100], //可选择的一页多少条
      dialogFormVisible_change: false,
      dialogFormVisible_view: false,
      dialogFormVisibleAdd: false,
      dialogFormVisible: false,
      dialogFormVisibleChange: false,
      exportLoading: false,
      disabledType: true,
      jobs: ['软件','硬件','人工智能'],
      academys: [],
      professions: [],
      searchParams: {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          userName: '',
          roleId: '',
          isBelong: '',
          enableFlag: '',
          academy: '',
          profession: '',
          entryYear: '',
          job: '',
          isShow: '',
          isManage: ''
        },
      },
      years: [],
      YesOrNot: [
        {
          name: '是',
          value: "1"
        },
        {
          name: '否',
          value: "0"
        },
      ],
      roles: [
        {
          name: '学生',
          value: "1003"
        },
        {
          name: '老师',
          value: "1002"
        },
        {
          name: '管理员',
          value: "1001"
        }
      ],
      roleMap: new Map([
        ['1001', '管理员'],
        ['1002', '老师'],
        ['1003', '学生'],
      ]),
    }
  },
  watch: {
    multipleSelection(val) {
      if (val.length > 0) {
        this.disabledType = false;
      } else {
        this.disabledType = true;
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      this.getAllYears();
      this.getDic();
      this.load();
      this.judgeRole();
    },
    getAllYears(){
      manageService.searchAllYears().then(res => {
        if (res.state){
          this.years = res.data;
        }
      })
    },
    // 字典初始化
    getDic(){
      // 院校名称
      dictionary.getDicByCode("academy").then(res => {
        if (res.state){
          this.academys = res.data;
        }
      })
      // 专业名称
      dictionary.getDicByCode("profession").then(res => {
        if (res.state){
          this.professions = res.data;
        }
      })
    },
    load(number) {
      if (number === 1) {
        this.searchParams.pageBean.pageNum = 1;
      }
      manageService.searchList(this.searchParams)
          .then(res => {
            this.tableData = res.rows;
            this.total = res.total;
          })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleSwitchChange(row){
      let userDetail = {
        id : row.userDetailId,
        isBelong : row.isBelong
      }
      MessageService.updateDetail(userDetail)
    },
    handleSwitchChange2(row){
      let userDetail = {
        id : row.userDetailId,
        enableFlag : row.enableFlag
      }
      MessageService.updateDetail(userDetail)
    },
    handleSwitchChange3(row){
      let userDetail = {
        id : row.userDetailId,
        isShow : row.isShow
      }
      MessageService.updateDetail(userDetail)
    },
    handleSwitchChange4(row){
      let userDetail = {
        id : row.userDetailId,
        isManage : row.isManage
      }
      MessageService.updateDetail(userDetail)
    },
    judgeRole() {
      const roleId = localStorage.getItem("roleId");
      if (roleId !== '1003') {
        this.isChange = true;
      }
      if (roleId === '1001') {
        this.isAdmin = true;
      }
    },
    exportFile() {
      manageService.export(this.searchParams)
          .then((res) => {
            processDataFlow(res, '团队成员信息汇总表.xlsx')
          })
          .finally(() => {
            this.exportLoading = false
          })
    },
    reset() {
      this.searchParams = {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          userName: '',
          roleId: '',
          isBelong: '',
          enableFlag: '',
          academy: '',
          profession: '',
          entryYear: '',
          job: '',
          isShow: '',
          isManage: ''
        },
      }
      this.load()
    },
    onSubmit() {

    },
    del() {
      let ids = this.multipleSelection.map((item) => item.id).join(",");
      let self = this;
      self
          .$confirm("此操作将删除已选择数据, 是否继续?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            manageService.delete(ids)
                .then((res) => {
                  if (res.state) {
                    self.$message.success(res.message);
                  } else {
                    self.$message.warning(res.message);
                  }
                }).finally(() => {
              self.load();
            });
          });

    },
    convertRole(row, column, cellValue) {
      return this.roleMap.get(cellValue) || cellValue;
    },
    changePwd(row) {
      this.userId = row.id;
      this.dialogFormVisibleChange = true;
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.addData = {};
    },
    handleEdit(row) {
      this.isShowEdit = '1';
      if (row.roleId === '1002') {
        this.isStudent = '2';
      }
      if (row.roleId === '1003') {
        this.isStudent = '1';
      }
      if (row.roleId === '1001') {
        this.isStudent = '1';
      }
      this.userDetailId = row.userDetailId;
      this.userName = row.userName;
      this.dialogFormVisible_change = true
    },
    viewItem(row) {
      this.isShowEdit = '2';
      if (row.roleId === '1002') {
        this.isStudent = '2';
      }
      if (row.roleId === '1003') {
        this.isStudent = '1';
      }
      if (row.roleId === '1001') {
        this.isStudent = '1';
      }
      this.userDetailId = row.userDetailId;
      this.userName = row.userName;
      this.dialogFormVisible_view = true
    },
    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      this.searchParams.pageBean.pageNum = val;
      this.load();
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      this.searchParams.pageBean.pageSize = val;
      this.load();
    },
  }
}
</script>

<style scoped lang="scss">

//.wareHouseBody {
//  margin: 0;
//  padding-bottom: 0.0625rem;
//  min-height: calc(100vh - 6.4rem);
//  // ::v-deep .el-table .cell {
//  //   line-height: 32px;
//  //   white-space: normal !important;
//  //   &.el-tooltip {
//  //     white-space: nowrap !important;
//  //   }
//  // }
//}
.role {
  margin-left: -45px;
}

.name {
  margin-left: -45px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

//.height100 {
//  height: calc(100vh - 6.7rem);
//}

.searchBox {
  margin-top: 10px;
  padding: 20px;
}

.tableData {
  //margin-top: 20px;
  border-radius: 10px;
  border: thin solid #eaebec;
  width: 98%;
  margin-left: 1%;
}
</style>
