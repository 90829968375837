<template>
  <div class="main">

    <div class="modal" v-show="visible">
      <img id="modalImg" src="" alt="" style=" height: 600px;" @click="close">
    </div>
    <div class="modal" v-show="show">
      <img id="modalImg2" src="" alt="" style=" height: 700px;" @click="close">
    </div>

    <div class="intro_top">
      <span>荣誉证书/</span>
      <em>HONOR</em>
    </div>
    <div class="intro_top">
      <span>教师荣誉/</span>
    </div>
    <div class="picture_box">
      <div class="card_teacher">
        <img src="@/assets/img/award/teacher/2023北斗杯优秀指导教师.jpg" alt="" id="img0" @click="showImage('img0')"/>
        <div style="margin: 5px;">
          <p style="font-size: 13px;">2023北斗杯优秀指导教师</p>
        </div>
      </div>
    </div>
    <div class="picture_box">
    </div>
    <div v-for="(yearData, year) in awardData" :key="year">
      <div class="intro_top">
        <span>{{ year }}级/</span>
        <em>AWARDS</em>
      </div>
      <div class="picture_box">
        <div v-for="(competitionData, competitionName) in yearData" :key="competitionName">
          <div class="intro_top_1">
            <span>{{ competitionName }}/</span>
            <em>Competition</em>
          </div>
          <div class="card" v-for="award in competitionData"
               :style="{ height: competitionName === '第六届（2023）全国大学生嵌入式芯片与系统设计竞赛' ||
               competitionName === '第九届3S杯全国大学生物联网技术与应用“三创”大赛' ||
               competitionName === '第八届3S杯全国大学生物联网技术与应用“三创”大赛' ||
               competitionName === '2023年中国高校计算机大赛-2023年网络技术挑战赛' ||
               competitionName === '第五届全国大学生嵌入式芯片与系统设计竞赛' ? '440px' : '' }">
            <img :src="baseUrl + award.imageUrl" alt="" @click="upload(award.imageUrl)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AwardService from "../../api/awards"
import { BASE_URL } from '@/utils/constant';

export default {
  components: {},
  data() {
    return {
      baseUrl: BASE_URL,
      visible: false,
      show: false,
      awardData: {},
      competitionYears: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      AwardService.searchBaseAwards().then(res => {
        const groupsByYear = {};
        res.data.forEach(award => {
          if (!groupsByYear[award.competitionYear]) {
            groupsByYear[award.competitionYear] = {};
          }
          if (!groupsByYear[award.competitionYear][award.competitionName]) {
            groupsByYear[award.competitionYear][award.competitionName] = [];
          }
          groupsByYear[award.competitionYear][award.competitionName].push(award);
        });
        this.awardData = groupsByYear;
      })
    },
    upload(pic) {
      window.open(this.baseUrl + pic, '_blank')
    },
    showImage(imgId) {

      this.visible = true
      document.getElementById("modalImg").src = document.getElementById(imgId).src;
    },
    close() {
      this.visible = false
      this.show = false
    }
  }

}
</script>


<style lang="less" scoped>
.main {
  width: 1200px;
  margin: 0 auto;
}

.card img, .card_1 img {
  width: 100%;
  height: 100%;
}

.card_teacher img {
  width: 100%;
}

.card,.card_teacher {
  text-align: center;
  height: 250px;
  width: 260px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  display: inline-block;
  margin: 10px 18px;
  vertical-align: top;
}


.card_1 {
  text-align: center;
  height: 440px;
  width: 260px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  display: inline-block;
  margin: 10px 18px;
  vertical-align: top;
}
</style>

<style scoped>

.intro_top {
  margin-top: 30px;
  line-height: 38px;
  padding-right: 5px;
  padding-left: 5px;
  width: max-content;
  background-color: rgba(221, 237, 239, 0.8);
  border-radius: 5px;
}

.intro_top span {
  font-size: 28px;
  color: #1f2c5c;;
}

.intro_top em {
  font-size: 16px;
  color: #2d2d2d;
}

.intro_top_1 {
  margin-top: 30px;
  line-height: 38px;
  padding-right: 5px;
  padding-left: 5px;
  width: max-content;
  background-color: rgba(221, 237, 239, 0.8);
  border-radius: 5px;
}

.intro_top_1 span {
  font-size: 20px;
  color: #1f2c5c;;
}

.intro_top_1 em {
  font-size: 16px;
  color: #2d2d2d;
}

</style>

<style scoped>
.picture_box {
  /* background-color: blueviolet; */
  display: flex;
  flex-wrap: wrap;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(205, 215, 216, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>
