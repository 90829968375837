<template>
  <div class="wareHouseBody">
    <div class="flex-column height100">
      <div class="searchBox">
        <el-form label-width="72px">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="成果类型">
                <el-select v-model="searchParams.params.type" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in types"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="成果年份">
                <el-select v-model="searchParams.params.researchYear" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in years"
                      :key="item.value"
                      :label="item"
                      :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否展示">
                <el-select v-model="searchParams.params.isShow" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in YesOrNot"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="学生姓名">
                  <student-tree
                      style="width: 100%"
                      :studentData="studentDataAll"
                      :existStudentIds="existStudentIds"
                      :studentDataProps="{value: 'value',label: 'label',children: 'children'}"
                      @update:userIds="handleUpdateStudent"
                      placeholder="请选择学生姓名"
                      class="position-select"
                      size="mini"
                      filterable>
                  </student-tree>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="指导教师">
                  <teacher-tree
                      style="width: 100%"
                      :teacherData="teacherDataAll"
                      :existTeacherIds="existTeacherIds"
                      :teacherDataProps="{value: 'value',label: 'label',children: 'children'}"
                      @update:userIds="handleUpdateTeacher"
                      placeholder="请选择老师姓名"
                      class="position-select"
                      size="mini"
                      clearable
                      filterable>
                  </teacher-tree>
                </el-form-item>
              </el-col>
            </el-row>
          <el-row :gutter="24" type="flex" align="middle" style="margin: 0">
            <el-row :span="24">
              <el-button type="primary" @click="handleAdd()" v-btn-permission="'user_add'">新增</el-button>
              <el-button type="primary" @click="del()"  v-btn-permission="'user_delete'" :disabled="disabledType">批量删除</el-button>
              <el-button type="primary" @click="load(1)">查询</el-button>
              <el-button type="primary" @click="exportFile()" :loading="exportLoading">
                导出
              </el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
            </el-row>
          </el-row>
        </el-form>
      </div>

      <div class="tableData" style="height: 100%">
        <el-table
            ref="filterTable"
            :data="researchList"
            :show-overflow-tooltip="true"
            @selection-change="handleSelectionChange"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="researchYear" label="成果时间" min-width="150"/>
          <el-table-column prop="type" label="成果类型" min-width="150"/>
          <el-table-column prop="name" label="成果名称" min-width="150"/>
          <el-table-column prop="studentName" label="学生姓名" min-width="150"/>
          <el-table-column prop="teacherName" label="指导教师" min-width="150"/>
          <el-table-column prop="applyDate" label="录用/申请日期" min-width="150"/>
          <el-table-column prop="publishDate" label="见刊/授权日期" min-width="150"/>
          <el-table-column prop="updationDate" label="上传时间" min-width="150"/>
          <el-table-column label="是否展示" min-width="100">
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.isShow"
                  active-color="#13ce66"
                  active-value = "1"
                  inactive-value = "0"
                  @change="handleSwitchChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="viewItem(scope.row)">详情</el-button>
              <el-button @click="handleEdit(scope.row)" size="mini" v-btn-permission="'user_edit'">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
            style="text-align: right;"
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="this.searchParams.pageBean.pageNum"
            :page-size="this.searchParams.pageBean.pageSize"
            :page-sizes="pageSizes" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <AddOrModifyDialog v-if="dialogFormVisible" :dialogFormVisible.sync="dialogFormVisible" :researchId="researchId" :type="type" @refresh="load"/>
  </div>
</template>

<script>
import ResearchService from "../../../api/research"
import AddOrModifyDialog from "@/components/Manage/Research/components/AddOrModify";
import StudentTree from "@/components/tree/StudentTree";
import TeacherTree from "@/components/tree/TeacherTree";
import manageService from "@/api/manage";
import {processDataFlow} from "@/utils";
import dictionary from "@/api/dictionary";
export default {
  name: 'Research',
  components: {AddOrModifyDialog,StudentTree,TeacherTree},
  data() {
    return {
      searchParams: {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          type: '',
          researchYear: '',
          isShow: '',
          userIds: [],
        }
      },
      types: [],
      years: [],
      pageSizes: [10, 20, 30, 50, 100], //可选择的一页多少条
      total: 0, //总条目数
      researchList: [],
      multipleSelection: [],
      disabledType: true,
      dialogFormVisible: false,
      researchId: '',
      type: '',
      YesOrNot: [
        {
          name: '是',
          value: "1"
        },
        {
          name: '否',
          value: "0"
        },
      ],
      studentDataAll: [],
      teacherDataAll: [],
      existStudentIds:[],
      existTeacherIds:[],
      studentIds:[],
      teacherIds:[],
      exportLoading: false
    }
  },
  watch: {
    multipleSelection(val) {
      if (val.length > 0) {
        this.disabledType = false;
      } else {
        this.disabledType = true;
      }
    },
  },
  mounted() {
    this.load();
    this.getDic();
    this.searchAllYear();
    this.searchAllUsers();
  },
  methods: {
    load(type) {
      if (type === 1) {
        this.searchParams.params.userIds = this.studentIds.concat(this.teacherIds);
      }
      ResearchService.searchList(this.searchParams).then(res => {
        this.researchList = res.rows;
        this.total = res.total;
      })
    },
    searchAllYear() {
      ResearchService.searchAllYear().then(res => {
        this.years = res.data;
      })
    },
    del() {
      let ids = this.multipleSelection.map((item) => item.id).join(",");
      let self = this;
      self
          .$confirm("此操作将删除已选择数据, 是否继续?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            ResearchService.delete(ids)
                .then((res) => {
                  if (res.state) {
                    self.$message.success(res.message);
                  } else {
                    self.$message.error(res.message);
                  }
                }).finally(() => {
              self.load();
            });
          });
    },
    reset() {
      this.searchParams = {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          type: '',
          researchYear: '',
          isShow: '',
          userIds: [],
        },
      }
      this.existProjectId = [];
      this.existStudentIds = [];
      this.existTeacherIds = [];
      this.$refs.filterTable.clearFilter();
      this.load()
    },
    searchAllUsers() {
      return new Promise((resolve, reject) => {
        manageService.searchAllUsers().then((res) => {
          this.studentDataAll = this.studentListToTree(res.data);
          this.teacherDataAll = this.teacherListToTree(res.data);
          resolve();
        })
      })
    },
    // 字典初始化
    getDic(){
      // 研究成果名称
      dictionary.getDicByCode("research_achievement").then(res => {
        if (res.state){
          this.types = res.data;
        }
      })
    },
    exportFile() {
      ResearchService.export(this.searchParams)
          .then(res => {
            processDataFlow(res, '研究成果信息汇总表.xlsx')
          })
          .finally(() => {
            this.exportLoading = false
          })
    },
    handleAdd() {
      this.type = "add";
      this.researchId = undefined;
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      this.type = 'edit';
      this.researchId = row.id;
      this.dialogFormVisible = true
    },
    viewItem(row) {
      this.type = 'detail';
      this.researchId = row.id;
      this.dialogFormVisible = true
    },
    handleSwitchChange(row){
      let research = {
        id : row.id,
        isShow : row.isShow
      };
      ResearchService.insertOrUpdate(research);
    },
    handleUpdateStudent(newId) {
      this.studentIds = newId;
    },
    handleUpdateTeacher(newId) {
      this.teacherIds = newId;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      this.searchParams.pageBean.pageNum = val;
      this.load();
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      this.searchParams.pageBean.pageSize = val;
      this.load();
    },
    studentListToTree(data) {

      let studentData = [];
      let professionMap = {}; // 用于快速查找专业节点
      let entryYearMap = {}; // 用于快速查找入学年份节点

      for (const student of data) {
        if (!student.profession || !student.entryYear || !student.id || !student.userName || student.roleId !== '1003') {
          continue; // 跳过缺失必要属性的学生对象
        }

        // 处理专业节点
        if (!professionMap[student.profession]) {
          const professionNode = this.createNode(this.createRandomId(), student.profession);
          studentData.push(professionNode);
          professionMap[student.profession] = professionNode;
          entryYearMap[student.profession] = {}; // 初始化当前专业的入学年份映射
        }

        // 处理入学年份节点
        const professionNode = professionMap[student.profession];
        if (!entryYearMap[student.profession][student.entryYear]) {
          const entryYearNode = this.createNode(this.createRandomId(), student.entryYear.toString());
          professionNode.children.push(entryYearNode);
          entryYearMap[student.profession][student.entryYear.toString()] = entryYearNode;
        }

        // 添加学生信息节点
        const entryYearNode = entryYearMap[student.profession][student.entryYear];
        entryYearNode.children.push(this.createNode(student.id, student.userName, true));
      }

      return studentData;
    },

    teacherListToTree(data) {
      let teacherData = [];
      let academyMap = {}; // 用于快速查找专业节点

      for (const teacher of data) {
        if (!teacher.id || !teacher.userName || !teacher.academy || teacher.roleId !== '1002') {
          continue;
        }

        if (!academyMap[teacher.academy]) {
          const academyNode = this.createNode(this.createRandomId(), teacher.academy);
          teacherData.push(academyNode);
          academyMap[teacher.academy] = academyNode;
        }

        const teacherNode = academyMap[teacher.academy];
        teacherNode.children.push(this.createNode(teacher.id, teacher.userName, true));
      }
      return teacherData;
    },

    // 创建树节点
    createNode(id, label, isLeaf = false) {
      return {
        id,
        label,
        children: [],
        ...(isLeaf && {isLeaf: true}), // 只有叶子节点才添加isLeaf属性
      };
    },

    // 生成一个随机的字符串作为 ID
    createRandomId() {
      return Math.random().toString(36).substr(2, 9);
    }
  }
}
</script>

<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}

.searchBox {
  margin-top: 10px;
  padding: 20px;
}

.tableData {
  border-radius: 20px;
  border: thin solid #eaebec;
  width: 98%;
  margin-left: 1%;
}
</style>