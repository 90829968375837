import request from "@/utils/request";

export default {
    searchAllType(){
        return request({
            url: "/activity/queryAllType",
            method: "get"
        })
    },

    searchList(data) {
        return request({
            url: "/activity/page",
            method: "post",
            data
        })
    },
    delete(ids) {
        return request({
            url:`/activity/remove?ids=${ids}`,
            method:"post"
        })
    },
    insertOrUpdate(data) {
        return request({
            url:"/activity/insertOrUpdate",
            method:"post",
            data
        })
    },
    queryById(id) {
        return request({
            url:`/activity/queryById?id=${id}`,
            method:"post",
        })
    },
    uploadImage(data) {
        return request({
            type: "file",
            url: "/activity/uploadImage",
            method: "post",
            data
        })
    },
    queryProjectImages() {
        return request({
            url: "/activity/queryActivityImages",
            method: "get"
        })
    }
}