<template>
  <div>
    <el-dialog width="40%" :title="diaTitle" :visible.sync="dialogVisb" @close="handleClose"
               :close-on-click-modal="false">
      <el-form :model="projectData" :rules="rules" ref="projectData" label-width="80px">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="项目年份" prop="projectYear">
              <div class="block">
                <el-date-picker
                    style="width: 100%"
                    :disabled="disableOrNot"
                    v-model="projectData.projectYear"
                    type="year"
                    value-format="yyyy"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="项目名称" prop="projectName">
              <el-input :disabled="disableOrNot" v-model="projectData.projectName"
                        placeholder="请输入项目名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="项目人员">
              <student-tree
                  style="width: 100%"
                  :disableOrNot="disableOrNot"
                  :existStudentIds="existIds"
                  :studentData="studentDataAll"
                  :studentDataProps="{value: 'value',label: 'label',children: 'children'}"
                  @update:userIds="handleUpdate"
                  placeholder="请选择学生姓名"
                  class="position-select"
                  size="mini"
                  filterable>
              </student-tree>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="所获论文" prop="thesisId" v-if="thesisIsNone">
              <research-tree
                  v-if="type === 'edit' || type === 'add'"
                  style="width: 100%"
                  :disableOrNot="disableOrNot"
                  :existResearchIds="existThesisIds"
                  :researchData="thesisDataAll"
                  :researchDataProps="{value: 'value',label: 'label',children: 'children'}"
                  @update:researchIds="handleUpdateThesis"
                  placeholder="请选择所获论文"
                  class="position-select"
                  size="mini"
                  filterable>
              </research-tree>
              <el-link
                  v-if="type === 'detail'"
                  class="mr30"
                  style="display: block"
                  v-for="file in thesisList"
                  :key="file.id"
                  :href="baseUrl + file.fileUrl"
                  type="primary"
                  target="_blank"
              >{{ file.fileName }}
              </el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="所获软著" prop="softId" v-if="softIsNone">
              <research-tree
                  v-if="type === 'edit' || type === 'add'"
                  style="width: 100%"
                  :disableOrNot="disableOrNot"
                  :existResearchIds="existSoftIds"
                  :researchData="softDataAll"
                  :researchDataProps="{value: 'value',label: 'label',children: 'children'}"
                  @update:researchIds="handleUpdateSoft"
                  placeholder="请选择所获软著"
                  class="position-select"
                  size="mini"
                  filterable>
              </research-tree>
              <el-link
                  v-if="type === 'detail'"
                  class="mr30"
                  style="display: block"
                  v-for="file in softList"
                  :key="file.id"
                  :href="baseUrl + file.fileUrl"
                  type="primary"
                  target="_blank"
              >{{ file.fileName }}
              </el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="所获专利" prop="patentId" v-if="patentIsNone">
              <research-tree
                  v-if="type === 'edit' || type === 'add'"
                  style="width: 100%"
                  :disableOrNot="disableOrNot"
                  :existResearchIds="existPatentIds"
                  :researchData="patentDataAll"
                  :researchDataProps="{value: 'value',label: 'label',children: 'children'}"
                  @update:researchIds="handleUpdatePatent"
                  placeholder="请选择所获专利"
                  class="position-select"
                  size="mini"
                  filterable>
              </research-tree>
              <el-link
                  v-if="type === 'detail'"
                  class="mr30"
                  style="display: block"
                  v-for="file in patentList"
                  :key="file.id"
                  :href="baseUrl + file.fileUrl"
                  type="primary"
                  target="_blank"
              >{{ file.fileName }}
              </el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="项目图片" v-if="imageIsNone">
              <el-upload v-if="type === 'edit' || type === 'add'"
                         :on-preview="upload"
                         :on-remove="handleRemove"
                         :before-upload="beforeUpload4"
                         :file-list="imageList"
                         action
                         multiple>
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <el-link
                  v-if="type === 'detail'"
                  class="mr30"
                  style="display: block"
                  v-for="image in imageList"
                  :key="image.id"
                  :href="baseUrl + image.imageUrl"
                  type="primary"
                  target="_blank"
              >{{ image.imageUrl.match(/[^\/]+$/)[0] }}
              </el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item v-if="disableOrNot" label="修改人">
              <el-input :disabled="disableOrNot" v-model="projectData.updatedBy"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item v-if="disableOrNot" label="修改时间">
              <el-input :disabled="disableOrNot" v-model="projectData.updationDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: right">
        <el-button @click="handleClose()">取消</el-button>
        <el-button v-if="!disableOrNot" type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import projectService from '@/api/project'
import manageService from '@/api/manage'
import {nanoid} from "nanoid";
import ResearchTree from "@/components/tree/ResearchTree";
import StudentTree from "@/components/tree/StudentTree";
import ResearchService from "@/api/research"
import { BASE_URL } from '@/utils/constant';

export default {
  components: {StudentTree,ResearchTree},
  name: 'AddOrModifyDialog',
  props: {
    type: String,
    projectId: String,
    dialogFormVisible: Boolean,
  },
  data() {
    return {
      baseUrl: BASE_URL,
      disableOrNot: false,
      diaTitle: '',
      thesisList: [],
      softList: [],
      patentList: [],
      imageList: [],
      projectData: {
        projectName: '',
        projectYear: '',
        userIds: [],
        fileList: [],
        imageList: []
      },
      rules: {
        projectYear: [{required: true, message: '项目年份不能为空', trigger: 'change'}],
        projectName: [{required: true, message: '项目名称不能为空', trigger: 'blur'}],
      },
      existIds: [],
      studentDataAll: [],
      thesisIsNone: true,
      softIsNone: true,
      patentIsNone: true,
      imageIsNone: true,
      thesisIds: [],
      existThesisIds: [],
      thesisDataAll: [],
      softIds: [],
      existSoftIds: [],
      softDataAll: [],
      patentIds: [],
      existPatentIds: [],
      patentDataAll: [],
    }
  },
  watch: {
    type: {
      handler(newval, oldval) {
        if (newval === 'add') {
          this.diaTitle = '新增'
          this.disableOrNot = false
        }
        if (newval === 'edit') {
          this.diaTitle = '编辑'
          this.disableOrNot = false
        }
        if (newval === 'detail') {
          this.diaTitle = '详情'
          this.disableOrNot = true
        }
      },
      immediate: true,
    },
  },
  computed: {
    dialogVisb: {
      get() {
        return this.dialogFormVisible
      },
      set(v) {
        this.$emit('update:dialogFormVisible', v)
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.$nextTick(() => {
        this.searchAllUsers().then(res => {
          this.searchAllResearch().then(res => {
            if (this.projectId && this.projectId !== '') {
              projectService.getById(this.projectId).then((res) => {
                if (res.state) {
                  this.projectData = res.data;
                  this.projectData.userIds = res.data.userIds
                  this.existIds = res.data.userIds
                  this.existThesisIds = res.data.researchInfos.filter(item => item.type === "论文").map(item => item.researchId);
                  this.existSoftIds = res.data.researchInfos.filter(item => item.type === "软著").map(item => item.researchId);
                  this.existPatentIds = res.data.researchInfos.filter(item => item.type === "专利").map(item => item.researchId);
                  if (this.projectData.imageList && this.projectData.imageList.length > 0) {
                    res.data.imageList.forEach(item => {
                      const newItem = {
                        ...item,
                        imageUrl: item.imageUrl,
                        name: item.imageUrl.match(/[^\/]+$/)[0],
                        uid: item.uid
                      };
                      this.imageList.push(newItem);
                    });
                  }

                  // 附件列表
                  if (this.projectData.fileList && this.projectData.fileList.length > 0) {
                    res.data.fileList.forEach(item => {
                      const newItem = {
                        ...item,
                        fileName: item.fileName,
                        fileUrl: item.fileUrl,
                        name: item.fileName,
                        type: item.type,
                        uid: item.uid
                      };
                      if (item.type === "论文") {
                        this.thesisList.push(newItem);
                      } else if (item.type === "软著") {
                        this.softList.push(newItem);
                      } else if (item.type === "专利") {
                        this.patentList.push(newItem);
                      }
                    });
                  }

                  if (this.type === "detail") {
                    if (this.thesisList && this.thesisList.length === 0) {
                      this.thesisIsNone = false;
                    }
                    if (this.softList && this.softList.length === 0) {
                      this.softIsNone = false;
                    }
                    if (this.patentList && this.patentList.length === 0) {
                      this.patentIsNone = false;
                    }
                    if (this.imageList && this.imageList.length === 0) {
                      this.imageIsNone = false;
                    }
                  }

                }
              })
            }
          })
        })
      });
    },

    // 保存
    save() {
      this.projectData.researchIds = this.thesisIds.concat(this.softIds).concat(this.patentIds);
      this.$refs['projectData'].validate((valid) => {
        if (valid) {
          if (this.type === 'add') {
            projectService.insertOrUpdate(this.projectData).then((res) => {
              if (res.state) {
                this.$message.success('新增成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
          if (this.type === 'edit') {
            projectService.insertOrUpdate(this.projectData).then((res) => {
              if (res.state) {
                this.$message.success('修改成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
        } else {
          return false;
        }
      });
    },

    upload(file) {
      window.open(this.baseUrl + file.imageUrl, '_blank')
    },

    // 编辑
    handleUpdate(newIds) {
      this.projectData.userIds = newIds;
    },

    handleUpdateThesis(newIds) {
      this.thesisIds = newIds;
    },
    handleUpdateSoft(newIds) {
      this.softIds = newIds;
    },
    handleUpdatePatent(newIds) {
      this.patentIds = newIds;
    },

    // 文件显示
    handlePictureCardPreview(file) {
      window.open(this.baseUrl + file.fileUrl, '_blank')
    },
    // 文件删除
    handleRemove(file, fileList) {
      if (this.projectData.fileList && this.projectData.fileList.length > 0) {
        let index = this.projectData.fileList.findIndex((o) => o.uid === file.uid)
        if (index > -1) {
          this.projectData.fileList.splice(index, 1)
        }
      }
      if (this.projectData.imageList && this.projectData.imageList.length > 0) {
        let index = this.projectData.imageList.findIndex((o) => o.uid === file.uid)
        if (index > -1) {
          this.projectData.imageList.splice(index, 1)
        }
      }
    },
    // 上传论文
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      projectService.uploadFile(form).then(res => {
        const item = {
          fileName: res.data.fileName,
          fileUrl: res.data.fileUrl,
          name: res.data.fileName,
          type: "thesis",
          uid: nanoid()
        };
        this.thesisList.push(item)
        this.projectData.fileList.push(item);
      });

      return false;
    },
    // 上传软著
    beforeUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      projectService.uploadFile(form).then(res => {
        const item = {
          fileName: res.data.fileName,
          fileUrl: res.data.fileUrl,
          name: res.data.fileName,
          type: "soft",
          uid: nanoid()
        };
        this.softList.push(item);
        this.projectData.fileList.push(item);
      });

      return false;
    },
    // 上传专利
    beforeUpload3(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      projectService.uploadFile(form).then(res => {
        const item = {
          fileName: res.data.fileName,
          fileUrl: res.data.fileUrl,
          name: res.data.fileName,
          type: "patent",
          uid: nanoid()
        };
        this.patentList.push(item);
        this.projectData.fileList.push(item);
      });

      return false;
    },

    //上传图片
    beforeUpload4(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      projectService.uploadImage(form).then(res => {
        const item = {
          imageUrl: res.data,
          name: res.data.match(/[^\/]+$/)[0],
          uid: nanoid()
        };
        this.imageList.push(item);
        this.projectData.imageList.push(item);
        console.log(this.projectData);
      });
      return false;
    },

    // 查询所有人员信息
    searchAllUsers() {
      return new Promise((resolve, reject) => {
        manageService.searchAllUsers().then((res) => {
          this.studentDataAll = this.listToTree(res.data);
          resolve();
        })
      })
    },

    searchAllResearch() {
      return new Promise((resolve, reject) => {
        ResearchService.queryAllResearch().then((res) => {
          const thesis = res.data.filter(item => item.type === "论文");
          this.thesisDataAll = this.researchListToTree(thesis);
          const soft = res.data.filter(item => item.type === "软著");
          this.softDataAll = this.researchListToTree(soft);
          const patent = res.data.filter(item => item.type === "专利");
          this.patentDataAll = this.researchListToTree(patent);
          resolve();
        })
      })
    },
    // // 查询已存在人员信息
    // getUserIds() {
    //   if (this.type === 'edit' || this.type === 'detail') {
    //     projectService.getUsersByProjectId(this.projectId).then((res) => {
    //       if (res.state) {
    //         this.projectData.userIds = res.data
    //         this.existIds = res.data
    //       }
    //     })
    //   }
    // },

    // 关闭弹窗
    handleClose() {
      this.existIds = [];
      this.projectData = {};
      this.dialogVisb = false;
    },

    // 人员树
    listToTree(data) {

      let treeData = [];
      let professionMap = {}; // 用于快速查找专业节点
      let entryYearMap = {}; // 用于快速查找入学年份节点

      for (const student of data) {
        if (!student.profession || !student.entryYear || !student.id || !student.userName || student.roleId !== '1003') {
          continue; // 跳过缺失必要属性的学生对象
        }

        // 处理专业节点
        if (!professionMap[student.profession]) {
          const professionNode = this.createNode(this.createRandomId(), student.profession);
          treeData.push(professionNode);
          professionMap[student.profession] = professionNode;
          entryYearMap[student.profession] = {}; // 初始化当前专业的入学年份映射
        }

        // 处理入学年份节点
        const professionNode = professionMap[student.profession];
        if (!entryYearMap[student.profession][student.entryYear]) {
          const entryYearNode = this.createNode(this.createRandomId(), student.entryYear.toString());
          professionNode.children.push(entryYearNode);
          entryYearMap[student.profession][student.entryYear.toString()] = entryYearNode;
        }

        // 添加学生信息节点
        const entryYearNode = entryYearMap[student.profession][student.entryYear];
        entryYearNode.children.push(this.createNode(student.id, student.userName, true));
      }

      return treeData;
    },

    // 成果树形结构数据
    researchListToTree(data) {

      let researchData = [];
      let yearMap = {};

      for (const research of data) {
        if (!research.researchYear || !research.name || !research.id) {
          continue;
        }

        if (!yearMap[research.researchYear]) {
          const yearNode = this.createNode(this.createRandomId(), research.researchYear);
          researchData.push(yearNode);
          yearMap[research.researchYear] = yearNode;
        }

        const nameNode = yearMap[research.researchYear];
        nameNode.children.push(this.createNode(research.id, research.name, true));
      }

      return researchData;
    },

    // 创建树节点
    createNode(id, label, isLeaf = false) {
      return {
        id,
        label,
        children: [],
        ...(isLeaf && {isLeaf: true}), // 只有叶子节点才添加isLeaf属性
      };
    },

    // 生成一个随机的字符串作为 ID
    createRandomId() {
      return Math.random().toString(36).substr(2, 9);
    }
  }

}
</script>
<style src="../../../../../node_modules/@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>
