<template>
<div class="main">
     <div class="intro_top">
       <span>联系方式</span>
       <em>TEAM INTRODUCTION</em>
     </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>

.intro_top {
   margin-top: 30px;
   line-height: 38px;
 }
 
 .intro_top span {
   font-size: 28px;
   color: #1f2c5c;;
 }
 
 .intro_top em {
   font-size: 16px;
   color: #2d2d2d;
 }
 .main{
   width:1200px;
   margin: 0 auto;
 }
</style>