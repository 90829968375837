import request from '@/utils/request.js';

export default {
    // 分页查询
    searchList(data) {
        return request({
            url:"/user/page",
            method:"post",
            data
        })
    },
    addUser(data) {
       return request({
           url:"/user/add",
           method:"post",
           data
       })
    },
    searchAllUsers() {
        return request({
            url:"/user/searchAllUsers",
            method:"get",
        })
    },
    // 批量删除
    delete(ids) {
        return request({
            url:`/user/remove?ids=${ids}`,
            method:"post"
        })
    },

    // 导出
    export(data) {
        return request({
            url:"/user/export",
            method:"post",
            responseType: 'blob',
            data
        })
    },

    edit(data) {
        return request({
            url:"/user/insertOrUpdateBatch",
            method:"post",
            data
        })
    },
    searchAllRoles() {
        return request({
            url:"/role/searchAllRoles",
            method:"get"
        })
    },
    searchAllYears() {
        return request({
            url:"/user/searchAllYears",
            method:"get"
        })
    },
}
