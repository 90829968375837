<template>
  <div class="emain">
    <el-container class="show-container">
     <!--  <el-header class="sub-header">
       
        <el-menu background-color="pink" mode="horizontal" text-color="white" active-text-color="yellow">
          <el-menu-item index="1" @click="routerContact1">联系方式</el-menu-item>
          <el-menu-item index="2" @click="routerContact2">地图导航</el-menu-item>
        </el-menu>
      </el-header>
      导航栏 -->
      <el-main style="padding: 0">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  /*
  methods: {
    routerContact1() {
      this.$router.push('/way')
    },
    routerContact2() {
      this.$router.push('/map')
    },
  }
  */
}
</script>

<style lang="less" scoped>
.emain img{
  width: 100%;
  height: 300px;
}
.sub-header {
  margin: 0 auto;
}
</style>