<template>
  <div class="wareHouseBody">
    <div class="flex-column height100">
      <div class="searchBox">
        <el-form label-width="72px">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="年份">
                <el-select v-model="searchParams.params.projectYear" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in years"
                      :key="item.value"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="名称">
                <el-input v-model="searchParams.params.projectName" placeholder="请输入项目名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="人员">
                <student-tree
                    style="width: 100%"
                    :studentData="studentDataAll"
                    :existStudentIds="existIds"
                    :studentDataProps="{value: 'value',label: 'label',children: 'children'}"
                    @update:userIds="handleUpdate"
                    placeholder="请选择学生姓名"
                    class="position-select"
                    size="mini"
                    filterable>
                </student-tree>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24" type="flex" align="middle" style="margin-left: 15px">
            <el-col :span="15">
              <el-button type="primary" @click="handleAdd()"  v-btn-permission="'user_add'">新增</el-button>
              <el-button type="primary" @click="exportFile()" :loading="exportLoading">导出</el-button>
              <el-button type="primary" @click="del()" v-btn-permission="'user_delete'" :disabled="disabledType">批量删除</el-button>
              <el-button type="primary" @click="load(1)">查询</el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="tableData" style="height: 80%">
        <el-table
            ref="filterTable"
            :data="projects"
            :show-overflow-tooltip="true"
            @selection-change="handleSelectionChange"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="projectYear" label="项目年份" min-width="50"/>
          <el-table-column prop="projectName" label="项目名称" min-width="150"/>
          <el-table-column prop="userName" label="项目人员" min-width="150"/>
          <el-table-column prop="projectYear" label="荣誉" min-width="50">
            <template slot-scope="scope">
              <el-button type="text" @click="viewAward(scope.row)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="viewItem(scope.row)">详情</el-button>
              <el-button @click="handleEdit(scope.row)" size="mini" v-btn-permission="'user_edit'">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: right;"
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="this.searchParams.pageBean.pageNum"
            :page-size="this.searchParams.pageBean.pageSize"
            :page-sizes="pageSizes" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <AddOrModifyDialog v-if="dialogFormVisible" :dialogFormVisible.sync="dialogFormVisible" :projectId="projectId" :type="type" @refresh="load"/>
    <el-dialog title="荣誉" :visible.sync="dialogFormVisibleAward" v-if="dialogFormVisibleAward" width="80%" :close-on-click-modal="false">
      <award :projectId="projectId" :isShowButton="isShowButton"></award>
    </el-dialog>
  </div>
</template>
<script>
import projectService from "@/api/project";
import StudentTree from '@/components/tree/StudentTree.vue';
import {processDataFlow} from "@/utils";
import AddOrModifyDialog from "@/components/Manage/Project/components/AddOrModify.vue";
import award from "@/components/Manage/Awards/Awards";
import manageService from "@/api/manage";
export default {
  name: 'Project',
  components: {
    AddOrModifyDialog,
    award,
    StudentTree
  },
  data() {
    return {
      isShow:'true',
      data:{},
      userList:[],
      years:[],
      total: 0, //总条目数
      multipleSelection: [],
      projectData: {},
      isShowButton: '',
      existIds: [],
      pageSizes: [10, 20, 30, 50,100], //可选择的一页多少条
      dialogFormVisible: false,
      dialogFormVisibleAward: false,
      exportLoading: false,
      disabledType: true,
      projectId: '',
      projectName: '',
      type: '',
      projects:[],
      studentDataAll:[],
      searchParams:{
        pageBean:{
          pageNum: 1,
          pageSize: 10
        },
        params: {
          projectName: '',
          projectYear: '',
          userIds: []
        }
      },
    }
  },
  watch: {
    multipleSelection(val) {
      if (val.length > 0) {
        this.disabledType = false;
      } else {
        this.disabledType = true;
      }
    },
  },
  mounted() {
    this.init();
  },
  methods:{
    init() {
      this.searchAllYears();
      this.load();
      this.searchAllUsers();
    },
    load(number) {
      if (number === 1) {
        this.searchParams.pageBean.pageNum = 1;
      }
      projectService.searchList(this.searchParams)
          .then(res => {
            this.projects = res.rows;
            this.total = res.total;
          });
    },
    searchAllYears(){
      projectService.searchAllYears().then(res=>{
        this.years = res.data;
      })
    },
    searchAllUsers() {
      manageService.searchAllUsers().then((res) => {
        this.studentDataAll = this.listToTree(res.data);
      })
    },
    handleUpdate(newIds) {
      this.searchParams.params.userIds = newIds;
    },
    viewAward(row) {
      this.isShowButton = '2';
      this.projectId = row.id;
      this.$emit("tableLoading");
      this.dialogFormVisibleAward = true;
    },
    reset(){
      this.existIds = [];
      this.searchParams = {
        pageBean:{
          pageNum: 1,
          pageSize: 10
        },
        params: {
          projectName: '',
          projectYear: '',
          userName:''
        },
      };
      this.load()
    },
    exportFile(){
      projectService.export(this.searchParams)
          .then((res) => {
            processDataFlow(res, '项目信息汇总表.xlsx')})
          .finally(() => {
            this.exportLoading = false
          })
    },
    del() {
      let ids = this.multipleSelection.map((item) => item.id).join(",");
      let self = this;
      self
          .$confirm("此操作将删除已选择数据, 是否继续?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            projectService.delete(ids)
                .then((res) => {
                  if (res.state) {
                    self.$message.success(res.message);
                  } else {
                    self.$message.warning(res.message);
                  }
                }).finally(() => {
              self.load();
            });
          });
    },
    handleAdd() {
      this.type = 'add';
      this.projectId = undefined;
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      this.type = 'edit';
      this.projectId = row.id;
      this.dialogFormVisible = true;
    },
    viewItem(row) {
      this.type = 'detail';
      this.projectId = row.id;
      this.dialogFormVisible = true
    },
    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      this.searchParams.pageBean.pageNum = val;
      this.load();
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      this.searchParams.pageBean.pageSize = val;
      this.load();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 人员树
    listToTree(data) {

      let treeData = [];
      let professionMap = {}; // 用于快速查找专业节点
      let entryYearMap = {}; // 用于快速查找入学年份节点

      for (const student of data) {
        if (!student.profession || !student.entryYear || !student.id || !student.userName || student.roleId !== '1003') {
          continue; // 跳过缺失必要属性的学生对象
        }

        // 处理专业节点
        if (!professionMap[student.profession]) {
          const professionNode = this.createNode(this.createRandomId(), student.profession);
          treeData.push(professionNode);
          professionMap[student.profession] = professionNode;
          entryYearMap[student.profession] = {}; // 初始化当前专业的入学年份映射
        }

        // 处理入学年份节点
        const professionNode = professionMap[student.profession];
        if (!entryYearMap[student.profession][student.entryYear]) {
          const entryYearNode = this.createNode(this.createRandomId(), student.entryYear.toString());
          professionNode.children.push(entryYearNode);
          entryYearMap[student.profession][student.entryYear.toString()] = entryYearNode;
        }

        // 添加学生信息节点
        const entryYearNode = entryYearMap[student.profession][student.entryYear];
        entryYearNode.children.push(this.createNode(student.id, student.userName, true));
      }

      return treeData;
    },

    // 创建树节点
    createNode(id, label, isLeaf = false) {
      return {
        id,
        label,
        children: [],
        ...(isLeaf && {isLeaf: true}), // 只有叶子节点才添加isLeaf属性
      };
    },

    // 生成一个随机的字符串作为 ID
    createRandomId() {
      return Math.random().toString(36).substr(2, 9);
    }

  }
}
</script>

<style scoped lang="scss">

//.wareHouseBody {
//  margin: 0;
//  padding-bottom: 0.0625rem;
//  min-height: calc(100vh - 6.4rem);
//  // ::v-deep .el-table .cell {
//  //   line-height: 32px;
//  //   white-space: normal !important;
//  //   &.el-tooltip {
//  //     white-space: nowrap !important;
//  //   }
//  // }
//}

.flex-column {
  display: flex;
  flex-direction: column;
}

//.height100 {
//  height: calc(100vh - 6.7rem);
//}

.searchBox {
  margin-top: 10px;
  padding: 20px;
}

.tableData {
  //margin-top: 20px;
  border-radius: 20px;
  border: thin solid #eaebec;
  width: 98%;
  margin-left: 1%;
}
</style>
