var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"modal"},[_c('img',{staticStyle:{"height":"600px"},attrs:{"id":"modalImg","src":"","alt":""},on:{"click":_vm.close}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"modal"},[_c('img',{staticStyle:{"height":"700px"},attrs:{"id":"modalImg2","src":"","alt":""},on:{"click":_vm.close}})]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"picture_box"},[_c('div',{staticClass:"card_teacher"},[_c('img',{attrs:{"src":require("@/assets/img/award/teacher/2023北斗杯优秀指导教师.jpg"),"alt":"","id":"img0"},on:{"click":function($event){return _vm.showImage('img0')}}}),_vm._m(2)])]),_c('div',{staticClass:"picture_box"}),_vm._l((_vm.awardData),function(yearData,year){return _c('div',{key:year},[_c('div',{staticClass:"intro_top"},[_c('span',[_vm._v(_vm._s(year)+"级/")]),_c('em',[_vm._v("AWARDS")])]),_c('div',{staticClass:"picture_box"},_vm._l((yearData),function(competitionData,competitionName){return _c('div',{key:competitionName},[_c('div',{staticClass:"intro_top_1"},[_c('span',[_vm._v(_vm._s(competitionName)+"/")]),_c('em',[_vm._v("Competition")])]),_vm._l((competitionData),function(award){return _c('div',{staticClass:"card",style:({ height: competitionName === '第六届（2023）全国大学生嵌入式芯片与系统设计竞赛' ||
             competitionName === '第九届3S杯全国大学生物联网技术与应用“三创”大赛' ||
             competitionName === '第八届3S杯全国大学生物联网技术与应用“三创”大赛' ||
             competitionName === '2023年中国高校计算机大赛-2023年网络技术挑战赛' ||
             competitionName === '第五届全国大学生嵌入式芯片与系统设计竞赛' ? '440px' : '' })},[_c('img',{attrs:{"src":_vm.baseUrl + award.imageUrl,"alt":""},on:{"click":function($event){return _vm.upload(award.imageUrl)}}})])})],2)}),0)])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro_top"},[_c('span',[_vm._v("荣誉证书/")]),_c('em',[_vm._v("HONOR")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro_top"},[_c('span',[_vm._v("教师荣誉/")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"5px"}},[_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v("2023北斗杯优秀指导教师")])])
}]

export { render, staticRenderFns }